/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable arrow-parens */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import './userList.scss';
import { baseUrl } from '../../API/constants';
// region IMAGEUSER

export const ImageUser = ({
  urlImage, userName, size, user,
}) => {
  const getFirstCharacterName = () => userName.charAt(0);

  const getAccepted = () => {
    if (user) {
      if (user.task_user) {
        return user.task_user.accept_status || false;
      }
      return null;
    }
    return null;
  };

  const accepted = getAccepted();

  const style = {
    width: `${size + 3}px`,
    height: `${size + 3}px`,
    border: '3px solid #fff',
  };

  if (accepted !== null) {
    style.border = `3px solid ${accepted ? '#0A784A' : '#848484'}`;
    style.opacity = `${accepted ? 1 : 0.5}`;
  }

  if (urlImage) {
    style.backgroundImage = `url('${baseUrl}${urlImage.slice(1, urlImage.length)}')`;
    style.backgroundPosition = 'center center';
    style.backgroundSize = 'cover';
    style.backgroundRepeat = 'no-repeat';
  }

  const title = `${userName}`;

  return (
    <div title={title} style={style} className="no-photo-user">
      <label style={{ lineHeight: `${size - 2}px` }}>
        {
          !urlImage
          && getFirstCharacterName()
        }
      </label>
    </div>
  );
};

ImageUser.propTypes = {
  urlImage: PropTypes.string,
  userName: PropTypes.string.isRequired,
  size: PropTypes.number,
  user: PropTypes.object,
};

ImageUser.defaultProps = {
  urlImage: null,
  size: 30,
  user: null,
};
// endregion

// region showMore
const ShowMore = ({ length, size }) => {
  const [hover, setHover] = React.useState(false);

  return (
    <>
      <div
        title="show  all users"
        style={{ width: `${size}px`, height: `${size}px` }}
        className="no-photo-user"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <label style={{ lineHeight: `${size}px` }}>{`+${length > 9 ? 9 : length}`}</label>
      </div>
      {/* {
        hover
        && <div>mostrar el resto de usuarios</div>
      } */}
    </>
  );
};

ShowMore.propTypes = {
  length: PropTypes.number.isRequired,
  size: PropTypes.number,
};

ShowMore.defaultProps = {
  size: 30,
};
// endregion

/**
 * USERSLIST
 */

const UsersList = ({
  users, label, zeroResutls, keylabel, useDiv, size, maxUsersToShow,
}) => {
  const [list, setList] = React.useState(users);
  const [usersHidden, setUsersHidden] = React.useState([]);

  React.useEffect(() => {
    if (maxUsersToShow) {
      setList(users.slice(0, maxUsersToShow));
      setUsersHidden(users.slice(maxUsersToShow, users.length));
    }
  }, [maxUsersToShow]);

  return useDiv
    ? (
      <div>
        {label && <label>{label}</label>}
        {
          users.length > 0
            ? (
              <div className="users-list">
                {
                  users.map(user => (
                    <ImageUser
                      urlImage={user.image_url}
                      user={user}
                      size={size}
                      key={user.id_user}
                      userName={keylabel ? `${user[keylabel].name} ${user[keylabel].last_name}` : `${user.name} ${user.last_name}`}
                    />
                  ))
                }
              </div>
            )
            : <p>{zeroResutls}</p>
        }
      </div>
    )
    : (
      <>
        {label && <label>{label}</label>}
        {
          list.length > 0
            ? (
              <div className="users-list">
                {
                  list.map(user => (
                    <ImageUser
                      urlImage={user.image_url}
                      user={user}
                      size={size}
                      key={user.id_user}
                      userName={keylabel ? `${user[keylabel].name} ${user[keylabel].last_name}` : `${user.name} ${user.last_name}`}
                    />
                  ))
                }
                {
                  usersHidden.length > 0
                  && <ShowMore size={size} length={usersHidden.length} />
                }
              </div>
            )
            : <p>{zeroResutls}</p>
        }
      </>
    );
};

UsersList.propTypes = {
  users: PropTypes.array,
  label: PropTypes.string,
  zeroResutls: PropTypes.string,
  keylabel: PropTypes.string,
  useDiv: PropTypes.bool,
  size: PropTypes.number,
  maxUsersToShow: PropTypes.number,
};

UsersList.defaultProps = {
  users: [],
  label: null,
  zeroResutls: 'No results',
  keylabel: null,
  useDiv: true,
  size: 30,
  maxUsersToShow: null,
};

export default UsersList;
