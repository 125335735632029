/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { CreateAuthRequest, fetchData } from '../../API/constants';
import Typeahead from '../../components/CustomizedTypeahead/typeahead';
import Pagination from '../../components/Pagination/pagination';
import ModalConfirm from '../../components/ModalConfirm/modalConfirm';

const userTypes = [
  '',
  'Admin',
  'Supervisor',
  'Employee',
];

const UserSections = ({ showLoader, NotificationManager, idUser, isProfile }) => {
  const [sectionsList, setSectionsList] = React.useState({ count: 0, rows: [] });
  const [form, setForm] = React.useState({ type: '', id_section: 0 });
  const [validated, setValidated] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [modalConfirm, setModalConfirm] = React.useState({ show: false, message: '' });

  const getUserSections = React.useCallback(async () => {
    showLoader(true);
    const request = await CreateAuthRequest('GET', null, true);
    fetchData(`users/${idUser}/sections?page=${page}`, request)
      .then((response) => {
        setSectionsList(response);
      })
      .finally(() => {
        showLoader(false);
      });
  }, [page]);

  React.useEffect(() => { getUserSections(); }, [getUserSections]);

  const handleFormChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);
    if (e.target.checkValidity()) {
      showLoader(false);
      const request = await CreateAuthRequest('POST', { id_user: idUser, user_type: form.type }, true);
      fetchData(`sections/${form.id_section}/users/single`, request)
        .then((response) => {
          setForm({ type: '' });
          NotificationManager.success('Sectionassigned', 'Success!', 5000);
          const obj = {
            count: sectionsList.count + 1,
            rows: [{ id_section: response.id_section, name: response.section.name, user_section: { user_type: response.user_type } }, ...sectionsList.rows],
          };
          setSectionsList(obj);
          const closebtn = document.getElementsByClassName('close rbt-close');
          if (closebtn.length > 0) {
            if (closebtn.length > 1) {
              closebtn[1].click();
            }
            closebtn[0].click();
          }
          setValidated(false);
        })
        .catch((error1) => {
          try {
            error1.then((response) => {
              NotificationManager.error(response.message, 'Error!', 5000);
            });
          } catch (error) {
            NotificationManager.error(error, 'Error!', 5000);
          }
        })
        .finally(() => {
          showLoader(false);
        });
    }
  };

  const handlePageClick = (newPage) => {
    setPage(newPage);
  };

  const hideModal = () => {
    setModalConfirm({ show: false, idSection: 0, message: '' });
  };

  const showModal = (section) => {
    setModalConfirm({
      show: true,
      idSection: section.id_section,
      message: section.name,
    });
  };

  const UnassignSection = async () => {
    const request = await CreateAuthRequest('DELETE', null, true);
    fetchData(`sections/${modalConfirm.idSection}/users/${idUser}`, request)
      .then(() => {
        const obj = {
          count: sectionsList.count - 1,
          rows: sectionsList.rows.filter((d) => d.id_section !== modalConfirm.idSection),
        };
        setSectionsList(obj);
        NotificationManager.success('Section unassigned successfully.', 'Success!', 5000);
      })
      .catch((err) => {
        try {
          err.then((er) => {
            NotificationManager.error(er.message, 'Error!', 5000);
          });
        } catch (error) {
          NotificationManager.error(error, 'Error!', 5000);
        }
      })
      .finally(() => {
        showLoader(false);
        hideModal();
      });
  };

  return (
    <div>
      <hr />
      {
        !isProfile
        && (
          <>
            <label style={{ fontSize: '20px', fontWeight: '700' }}>Sections</label>
            <h5>Assign Section</h5>
            <form className={`form needs-validation ${validated ? ' was-validated' : ''}`} onSubmit={handleSubmit} noValidate>
              <div className="row">
                <div className="col-4">
                  <div className="form-group">
                    <label>Section</label>
                    <Typeahead
                      labelKey="name"
                      url={`${process.env.REACT_APP_API_URL}sections`}
                      multiple={false}
                      minLength={3}
                      onChange={(option) => handleFormChange({ target: { name: 'id_section', value: option[0]?.id_section } })}
                      placeholder="Filter by section..."
                      itemChildren={(option) => (
                        <span>{`${option.id_section} ${option.name}`}</span>
                      )}
                      inputProps={{ required: 'required' }}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>User type</label>
                    <select className="form-control input-shadow" name="type" value={form.type} onChange={handleFormChange}>
                      <option value="">Choose user type</option>
                      <option value="3">Employee</option>
                      <option value="2">Supervisor</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-4 tac">
                  <button type="submit" className=" mg-top btn btn-primary btn-radius">Assign</button>
                </div>
              </div>

            </form>
          </>
        )
      }

      <div className="mg-top">
        <h5>Sections List</h5>
        <div className="table-section">
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Section</th>
                  <th>User Type</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                {
                  sectionsList.count > 0
                    ? sectionsList.rows.map((d) => (
                      <tr>
                        <td>{d.name}</td>
                        <td>{d.user_section && userTypes[d.user_section.user_type]}</td>
                        <td>
                          <button type="button" className="btn btn-danger btn-radius" onClick={() => showModal(d)}> Unassign</button>
                        </td>
                      </tr>
                    ))
                    : <tr><td colSpan="3">0 sections found</td></tr>
                }
              </tbody>
            </table>
            <Pagination
              total={sectionsList.count}
              page={page}
              limit={10}
              pageClick={handlePageClick}
              showLimit={false}
            />
          </div>
        </div>
      </div>
      <ModalConfirm
        show={modalConfirm.show}
        message={modalConfirm.message}
        type="unassign"
        onHide={hideModal}
        onConfirm={UnassignSection}
      />
    </div>
  );
};

UserSections.propTypes = {
  NotificationManager: PropTypes.object,
  showLoader: PropTypes.func,
  idUser: PropTypes.number.isRequired,
  isProfile: PropTypes.bool.isRequired,
};

UserSections.defaultProps = {
  NotificationManager: null,
  showLoader: (f) => f,
};

export default UserSections;
