/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import CalendarComponenent from './calendarComponent';
import { fetchData, CreateAuthRequest } from '../../API/constants';
import './calendar.scss';

const today = moment().tz('Atlantic/Bermuda');

const CalendarView = ({ showLoader }) => {
  const [taskList, setTaskList] = React.useState([]);
  const [dateFilter, setDatesFilter] = React.useState({
    startDate: today.startOf('month').toISOString(),
    endDate: today.endOf('month').toISOString(),
  });

  const getTask = React.useCallback(async () => {
    showLoader(true);
    const request = await CreateAuthRequest('GET', null, true);
    fetchData(`tasks/by/date?deadline_date=${dateFilter.startDate}|${dateFilter.endDate}`, request)
      .then((response) => {
        let tasks = [];

        for (const key in response) {
          if (response.hasOwnProperty(key)) {
            const element = response[key];
            tasks = [...tasks, ...element];
          }
        }
        const mapTask = tasks.map((task) => {
          const obj = {
            allDay: false,
            title: task.title,
            start: new Date(moment(task.deadline_date).toISOString()),
            day: moment(task.deadline_date).format('dddd DD, '),
            date: moment(task.deadline_date),
            status: task.task_status.status,
            url: task.id_task_parent ? `/pannel/tasks/detail/${task.id_task_parent}/subtask/${task.id_task}` : `/pannel/tasks/detail/${task.id_task}`,
          };
          if (task.deadline_date) {
            obj.end = new Date(moment(task.deadline_date).add(1, 'hours').tz('Atlantic/Bermuda').toISOString());
            // obj.end = obj.start;
          } else {
            obj.end = obj.start;
          }
          return obj;
        });
        setTaskList(mapTask);
      }).finally(() => {
        showLoader(false);
      });
  }, [dateFilter]);

  React.useEffect(() => {
    getTask();
  }, [getTask]);

  const ChangeDates = React.useCallback(({ startDate, endDate }) => {
    console.log(startDate.format("DD/MMM/YYYY"), endDate.format("DD/MMM/YYYY"));
    setDatesFilter({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    });
  }, []);

  return (
    <div className="card calendar-view" style={{ height: 'calc(100vh - 82px)' }}>
      <CalendarComponenent events={taskList} ChangeDates={ChangeDates} />
    </div>
  );
};

CalendarView.propTypes = {
  showLoader: PropTypes.func,
};

CalendarView.defaultProps = {
  showLoader: (f) => f,
};

export default CalendarView;
