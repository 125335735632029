/* eslint-disable react/jsx-filename-extension */
/* eslint-disable arrow-parens */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import useClickOutside from '../../hooks/useClickOutside';

const DropDown = ({ options, onSelectOption, defaultSelected }) => {
  const [selected, setSelected] = React.useState({});
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (defaultSelected) {
      const xd = options.find(option => option.id === defaultSelected);
      setSelected(xd);
    }
  }, [defaultSelected]);

  const handleSelection = (option) => {
    onSelectOption(option);
    setIsOpen(false);
  };

  const handleButonClick = () => {
    setIsOpen(!isOpen);
  };

  const hideOptions = () => {
    // setIsOpen(false);
  };

  const clickRef = useClickOutside(isOpen, () => {
    setIsOpen(false);
  });

  return (
    <div className="btn-group" ref={clickRef} style={{ display: 'block' }}>
      <button
        type="button"
        className={`btn btn-outline-${selected.class} dropdown-toggle`}
        onClick={handleButonClick}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onBlur={hideOptions}
      >
        <i className="fas fa-flag" />
        {` ${selected.name}`}
      </button>
      <div className={`dropdown-menu ${isOpen ? 'display' : ''}`}>
        {
          options.map(option => (
            <button key={option.name} type="button" className="dropdown-item" onClick={() => handleSelection(option)}>
              <i style={{ color: `${option.color}` }} className="fas fa-flag" />
              {` ${option.name}`}
            </button>
          ))
        }
      </div>
    </div>
  );
};

DropDown.propTypes = {
  options: PropTypes.array,
  onSelectOption: PropTypes.func,
  defaultSelected: PropTypes.number,
};

DropDown.defaultProps = {
  options: [],
  onSelectOption: f => f,
  defaultSelected: null,
};

export default DropDown;
