/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import ModalDecline from './modalDecline';

const AccepteDeclineTask = ({ handleOnSubmit }) => {
  const [showModalDecline, setShowModalDecline] = React.useState(false);

  const handleShowModalDecline = () => {
    setShowModalDecline(!showModalDecline);
  };

  const handleAcceptTask = () => {
    handleOnSubmit('accepted');
  };

  const handleDeclineTask = (reason) => {
    handleOnSubmit('declined', reason);
    handleShowModalDecline();
  };

  return (
    <div className="col-md-5 col-12 accept-decline-task tar">
      <span className="lbl-info">You have been invited to this task.</span>
      <button type="button" className="btn btn-ligth accept" onClick={handleAcceptTask}>
        <i className="fas fa-check" />
        {' Accept'}
      </button>
      <button type="button" className="btn btn-ligth decline" onClick={handleShowModalDecline}>
        <i className="fas fa-trash" />
        {' Decline'}
      </button>
      <ModalDecline
        show={showModalDecline}
        onHide={handleShowModalDecline}
        onConfirm={handleDeclineTask}
      />
    </div>
  );
};
AccepteDeclineTask.propTypes = {
  handleOnSubmit: PropTypes.func.isRequired,
};

export default AccepteDeclineTask;
