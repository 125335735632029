/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import showdown from 'showdown';
import { useParams, Link, useHistory } from 'react-router-dom';
import Header from '../../components/header/header';
import InputFile from '../../components/Files/inputFile';
import FilesList from '../Wikis/filesList';
import ModalConfirm from '../../components/ModalConfirm/modalConfirm';
import { CreateAuthRequest, fetchData } from '../../API/constants';
import './jobs.scss';

const JobDetails = ({ showLoader, NotificationManager }) => {
  const history = useHistory();
  const converter = new showdown.Converter({ emoji: true });
  const [jobInfo, setJobInfo] = React.useState({
    id_job: 0,
    id_section: 0,
    title: '',
    description: null,
    abstract: null,
    is_recruiting: false,
    disabled: false,
    createdAt: '',
    updatedAt: '',
    section: null,
  });
  const [showModal, setShowModal] = React.useState(false);
  const { idJob } = useParams();

  const getJobInfo = React.useCallback(async () => {
    if (idJob) {
      const request = await CreateAuthRequest('GET', null, true);
      fetchData(`sections/jobs/${idJob}`, request)
        .then((response) => {
          setJobInfo(response);
        });
    }
  }, [idJob]);

  React.useEffect(() => { getJobInfo(); }, [getJobInfo]);

  const showDescription = () => {
    if (jobInfo.description && jobInfo.description !== '') {
      return { __html: converter.makeHtml(jobInfo.description) };
    }
    return { __html: '<p>Not description yet</p>' };
  };

  const handleFileUpload = async (file) => {
    showLoader(true);
    const request = await CreateAuthRequest('POST', null, false);
    const body = new FormData();
    body.append('file', file);
    request.body = body;

    fetchData(`sections/jobs/${idJob}/files`, request)
      .then((response) => {
        setJobInfo({ ...jobInfo, job_files: [...jobInfo.job_files, response] });
        NotificationManager.success('File has been uploaded successfully.', 'Success!', 5000);
      })
      .finally(() => showLoader(false));
  };

  const handleFileDelete = async (file) => {
    const request = await CreateAuthRequest('DELETE', null, true);
    fetchData(`sections/jobs/${idJob}/files/${file.id_job_file}`, request)
      .then(() => {
        NotificationManager.success('File deleted successfully.', 'Success!', 5000);
        const newJobfiles = jobInfo.job_files.filter((f) => f.id_job_file !== file.id_job_file);
        setJobInfo({
          ...jobInfo,
          job_files: newJobfiles,
        });
      })
      .catch(() => {
        NotificationManager.error('An error ocurred. Please try again later.', 'Error!', 500);
      });
  };

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const deleteJob = async () => {
    const request = await CreateAuthRequest('PUT', { disabled: !jobInfo.disabled }, true);
    fetchData(`sections/jobs/${idJob}`, request)
      .then(() => {
        NotificationManager.success('Job has bean updated.', 'Success!', 5000);
        setJobInfo({ ...jobInfo, disabled: !jobInfo.disabled });
        handleShowModal();
      });
  };

  return (
    <div className="job-details">
      <Header title="Job details" />
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col">
              <h4 className="mg-0">{jobInfo.title}</h4>
            </div>
            <div className="col-3 tar btn-container">
              <buton type="button" style={{ marginRight: '5px' }} className={`btn btn-sm btn-${jobInfo.disabled ? 'success' : 'danger'} btn-radius`} onClick={handleShowModal}>{jobInfo.disabled ? 'Activate' : 'Deactivate'}</buton>
              <Link to={`/pannel/jobs/edit/${idJob}`} className="btn btn-sm btn-primary btn-radius">
                <i className="fas fa-edit" />
                {' Edit'}
              </Link>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-md-6">
              <label>Section</label>
              <p>{jobInfo.section && jobInfo.section.name}</p>
            </div>
            <div className="col-12 col-md-6">
              <label>Is Recruiting?</label>
              <p>{jobInfo.is_recruiting ? 'YES' : 'NO'}</p>
            </div>

            <div className="col-12">
              <label>Abstract</label>
              <p>{jobInfo.abstract ? jobInfo.abstract : 'N/A'}</p>
            </div>
            <div className="col-12">
              <label>Description</label>
              <div className="mg-top" dangerouslySetInnerHTML={showDescription()} />
            </div>
          </div>
          <InputFile
            label="Files"
            handleFileUpload={handleFileUpload}
            NotificationManager={NotificationManager}
          />
          <FilesList
            list={jobInfo.job_files}
            idKey="id_job_file"
            labelKey="original_filename"
            handleDeleteFile={handleFileDelete}
          />

          <ModalConfirm
            show={showModal}
            message={jobInfo.title}
            type={jobInfo.disabled ? 'activate' : 'deactivate'}
            onHide={handleShowModal}
            onConfirm={deleteJob}
          />

        </div>
      </div>
    </div>
  );
};

JobDetails.propTypes = {
  NotificationManager: PropTypes.object,
  showLoader: PropTypes.func,
};

JobDetails.defaultProps = {
  NotificationManager: null,
  showLoader: (f) => f,
};

export default JobDetails;
