/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import DefaultEvent from './event';

const localizer = momentLocalizer(moment);
// //const today = new Date();
// ////month event
// const MonthEvent = ({ event }) => {
//   return <div className="rc-event-container">
//     <label title={event.title + ' ' + event.day}>
//       {moment(event.date).format("h A")} {event.title} </label>
//   </div>
// }

// MonthEvent.propTypes = {
//   event: PropTypes.string
// }

// region CALENDAR
const CalendarComponent = ({ events, ChangeDates }) => {
  const [view, setView] = React.useState('month');
  const [date, setDate] = React.useState(new Date());
  const [eventList, setEventList] = React.useState([]);

  React.useEffect(() => {
    setEventList(events);
  }, [events]);

  // const ShowMore = (count) => {
  //   return <div className="rbc-event">Mostrar {count} más</div>
  // }

  // const messages = {
  //   showMore: (count) => ShowMore(count),
  //   week: "Semana",
  //   day: "Día",
  //   month: "Mes",
  //   previous: "Anterior",
  //   next: "Siguiente",
  //   today: "Hoy",
  //   yesterday: "Ayer",
  //   tomorrow: "Mañana",
  //   noEventsInRange: "No hay reservaciones agendados en este rango de fechas"
  // };

  function ChangeView(newView) {
    setView(newView);
  }
  // currentView, trigger
  function onNavigate(dateMoment) {
    // const date = moment(dateMoment);
    setDate(dateMoment);

    const start = moment(dateMoment).startOf('month');
    const end = moment(dateMoment).endOf('month');

    ChangeDates({ startDate: start, endDate: end });
    // if (currentView === 'day') {
    //     ChangeDates({ fecha_inicio: date, fecha_fin: date });
    // }
    // else if (currentView === "month") {
    //     const start = moment(dateMoment).startOf('month'),
    //         end = moment(dateMoment).endOf('month');
    //
    //     ChangeDates({ fecha_inicio: start, fecha_fin: end });
    // } else {
    //     const newWeek = moment(dateMoment).week();
    //     const start = moment().week(newWeek).weekday(0),
    //         end = moment().week(newWeek).weekday(6);
    //     ChangeDates({ fecha_inicio: start, fecha_fin: end });
    // }
  }

  return (
    <div className="calendar-section  card-body padding-block" style={{ height: '700px' }}>
      <Calendar
        localizer={localizer}
        components={{
          month: {
            event: DefaultEvent,
          },
          event: DefaultEvent,
          week: {
            event: DefaultEvent,
          },
        }}
        defaultView="month"
        view={view}
        date={date}
        views={{ month: true, week: true, day: true }}
        // messages={messages}
        events={eventList}
        onNavigate={(dateNew, currentView, trigger) => onNavigate(dateNew, currentView, trigger)}
        formats={{
          weekdayFormat: 'ddd',
          eventTimeRangeFormat: () => null,
        }}
        onView={ChangeView}
        // drilldownView="day"
        startAccessor="start"
        endAccessor="end"
      // popup={true}
      // selectable={true}
      // showMultiDayTimes
      // selectable
      // onSelectEvent={event => this.handleEventClick(event, history)}
      />
    </div>
  );
};

CalendarComponent.propTypes = {
  ChangeDates: PropTypes.func,
  events: PropTypes.array,
};

CalendarComponent.defaultProps = {
  events: [],
  ChangeDates: (f) => f,
};

// endregion

export default CalendarComponent;
