/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-useless-escape */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import jwt from 'jwt-decode';
import { useHistory, useLocation, Link } from 'react-router-dom';
import {
  fetchData, CreateAuthRequest, makeUrlGET,
} from '../../API/constants';
import InputDebounce from '../../components/InputDebounce/inputDebounce';
import Pagination from '../../components/Pagination/pagination';
import ModalCancelNotification from './modalCancel';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_NOTIFICATIONS_LIST':
      return { ...state, notificationsList: action.payload };
    case 'SET_FILTER_SEARCH':
      return { ...state, filterSearch: action.payload, filterPage: 1 };
    case 'SET_FILTER_SECTION':
      return { ...state, filterSection: action.payload };
    case 'SET_FILTER_TYPE':
      return { ...state, filterType: action.payload };
    case 'SET_FILTER_PAGE':
      return { ...state, filterPage: action.payload };
    case 'SET_FILTER_ACTIVE':
      return { ...state, filterActive: action.payload };
    case 'SET_URL':
      return { ...state, url: action.payload, filterPage: 1 };
    case 'SET_COLUMNS':
      return { ...state, columns: action.payload };
    case 'SET_USER_PERMISSIONS':
      return { ...state, hasUserPermissions: action.payload };
    case 'SET_SHOW_MODAL':
      return { ...state, showModalCancel: action.payload };
    case 'SET_NOTIFICATION_TO_DELETE':
      return { ...state, idNotificationToDelete: action.payload };
    case 'RESET_FILTERS':
      return {
        ...state,
        filterSearch: '',
        filterSection: '',
        filterType: '',
        filterPage: 1,
        filterActive: '',
      };
    default:
      return { ...state };
  }
};

const NotificationsList = ({ showLoader, NotificationManager }) => {
  const history = useHistory();
  const location = useLocation();
  const initialState = {
    notificationsList: { count: 0, rows: [] },
    filterSearch: '',
    filterSection: null,
    filterType: '',
    filterActive: '',
    filterPage: location.state ? location.state.page : 1,
    url: 'me/notifications',
    columns: ['ID', 'Title', 'Date', 'Topic'],
    hasUserPermissions: false,
    showModalCancel: false,
    idNotificationToDelete: null,
  };

  const user = jwt(sessionStorage.getItem('token'));

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const {
    notificationsList,
    filterSearch,
    filterSection,
    filterType,
    filterPage,
    filterActive,
    url,
    columns,
    hasUserPermissions,
    showModalCancel,
    idNotificationToDelete,
  } = state;

  const getNotificationsList = React.useCallback(async () => {
    showLoader(true);
    const urlHelper = `${url}?limit=10&order=-id_notification&${makeUrlGET({
      page: filterPage,
      search: encodeURIComponent(filterSearch),
      id_section: filterSection,
      type: filterType,
      disabled: filterActive,
    })}`;
    const request = await CreateAuthRequest('GET', null, true);
    fetchData(urlHelper, request).then((response) => {
      dispatch({ type: 'SET_NOTIFICATIONS_LIST', payload: response });
    })
      .catch((response) => {
        response.then((r) => {
          NotificationManager.error(r.message, 'Error!', 5000);
        });
      })
      .finally(() => { showLoader(false); });
  }, [filterSearch,
    filterType, filterSection,
    filterPage, filterActive, url, NotificationManager, showLoader]);

  React.useEffect(() => {
    getNotificationsList();
  }, [getNotificationsList]);

  const getUserPermissions = React.useCallback(async () => {
    showLoader(true);
    const request = await CreateAuthRequest('GET', null, true);
    fetchData('me/supervisor', request).then((response) => {
      const isSupervisor = response.sections || response.tasks;
      dispatch({ type: 'SET_USER_PERMISSIONS', payload: isSupervisor });
    })
      .catch((response) => {
        response.then((r) => {
          NotificationManager.error(r.message, 'Error!', 5000);
        });
      })
      .finally(() => { showLoader(false); });
  }, [NotificationManager, showLoader]);

  React.useEffect(() => {
    getUserPermissions();
  }, [getUserPermissions]);

  // const handleChangeTypeFilter = ({ target }) => {
  //   dispatch({ type: 'SET_FILTER_TYPE', payload: target.value });
  // };

  // const handleChangeSectionFilter = (value) => {
  //   dispatch({ type: 'SET_FILTER_SECTION', payload: value });
  // };

  const handleSearchByName = (text) => {
    dispatch({ type: 'SET_FILTER_SEARCH', payload: text });
  };

  const handleDetail = (noti) => {
    const helperLocation = {
      pathname: `${location.pathname}`,
      state: { filterPage },
    };
    history.replace(helperLocation);
    history.push(`/pannel/notifications/details/${noti.id_notification}`);
  };

  const handlePageClick = (page) => {
    dispatch({ type: 'SET_FILTER_PAGE', payload: page });
  };

  const handleFilterActive = ({ target }) => {
    dispatch({ type: 'SET_FILTER_ACTIVE', payload: target.value });
  };

  const handleSwitchUrl = ({ target }) => {
    dispatch({ type: 'SET_URL', payload: target.name });
    if (target.name === 'me/notifications') {
      dispatch({ type: 'SET_COLUMNS', payload: ['ID', 'Notification', 'Date', 'Topic'] });
    } else {
      dispatch({ type: 'SET_COLUMNS', payload: ['ID', 'Title', 'Send Date', 'Topic', 'Created by', 'Status', 'Options'] });
    }
  };

  const valid = (current) => {
    const date = moment(current).tz('Atlantic/Bermuda');
    const today = moment().tz('Atlantic/Bermuda');
    return date.isAfter(today);
  };

  const isUrl = (str) => /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(str);

  const hasHTTP = (str) => {
    if (str.includes('http')) {
      return str;
    }
    return `//${str}`;
  };

  const formatMessage = (msg) => {
    const res = msg.split(' ');
    const helper = [];
    for (let i = 0; i < res.length; i += 1) {
      const element = res[i];
      if (isUrl(element)) {
        const urlHelper = hasHTTP(element);
        helper.push(<a href={urlHelper} target="blank">{element}</a>);
        helper.push(' ');
      } else {
        helper.push(`${element} `);
      }
    }
    return helper;
  };

  const handleHideModal = (idNotification) => {
    dispatch({ type: 'SET_SHOW_MODAL', payload: !showModalCancel });
    if (idNotification) {
      dispatch({ type: 'SET_NOTIFICATION_TO_DELETE', payload: idNotification });
    }
  };

  const cancelNotification = async () => {
    showLoader(true);
    const nttodelete = idNotificationToDelete;
    const request = await CreateAuthRequest('PUT', { disabled: true }, true);
    fetchData(`notifications/${nttodelete}`, request)
      .then(() => {
        const newList = notificationsList.rows.map((n) => (n.id_notification === nttodelete
          ? { ...n, disabled: true }
          : { ...n }));
        dispatch({
          type: 'SET_NOTIFICATIONS_LIST',
          payload: { notificationsList: notificationsList.count, rows: newList },
        });
      })
      .catch((response) => {
        response.then((r) => {
          NotificationManager.error(r.message, 'Error!', 5000);
        });
      })
      .finally(() => {
        dispatch({ type: 'SET_SHOW_MODAL', payload: false });
        dispatch({ type: 'SET_NOTIFICATION_TO_DELETE', payload: null });
        showLoader(false);
      });
  };

  const mapRow = (row) => {
    if (url === 'me/notifications') {
      return (
        <tr key={row.id_notification}>
          <td>{row.id_notification}</td>
          <td>
            <label><b>{row.title}</b></label>
            <p>{formatMessage(row.message)}</p>
          </td>
          <td>{moment(row.createdAt).tz('Atlantic/Bermuda').format('DD/MMM/YYYY hh:mm a')}</td>
          <td>{row.type}</td>
        </tr>
      );
    }
    return (
      <tr key={row.id_notification}>
        <td>{row.id_notification}</td>
        <td>{row.title}</td>
        <td>{row.sent_date !== null ? moment(row.sent_date).tz('Atlantic/Bermuda').format('DD/MMM/YYYY hh:mm a') : moment(row.createdAt).tz('Atlantic/Bermuda').format('DD/MMM/YYYY hh:mm a')}</td>
        <td>{row.type}</td>
        <td>{row.user && `${row.user.name} ${row.user.last_name}`}</td>
        <td>{row.disabled ? 'Inactive' : 'Active'}</td>
        <td className="tac">
          {
            (valid(row.sent_date) && !row.disabled)
              ? <button type="button" className="btn btn-danger btn-sm btn-radius" onClick={() => handleHideModal(row.id_notification)}>Cancel</button>
              : ''
          }
          <button type="button" className="btn btn-primary btn-sm btn-radius" onClick={() => handleDetail(row)}>
            <i className="fas fa-ellipsis-h" />
          </button>
        </td>
      </tr>
    );
  };

  return (
    <div className="users-list">
      <div className="row">
        <div className="col-12 col-md-6">
          <InputDebounce
            placeholder="Search notification by title..."
            handleInputChange={handleSearchByName}
            value={filterSearch}
          />
        </div>
        <div className="col-12 col-md-3">
          <select className="form-control input-shadow" value={filterActive} onChange={handleFilterActive}>
            <option value="">Active/Inactive</option>
            <option value="0">Active</option>
            <option value="1">Inactive</option>
          </select>
        </div>
      </div>
      <div className="card mg-top">
        <div className="card-body">
          {
            (user.user_type === 1 || hasUserPermissions)
            && (
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="btn-group shadow" role="group">
                    <button
                      type="button"
                      name="me/notifications"
                      className={url === 'me/notifications' ? 'btn btn-primary' : 'btn btn-ligth'}
                      onClick={handleSwitchUrl}
                    >
                      Mine
                    </button>
                    <button
                      type="button"
                      name="notifications"
                      className={url === 'notifications' ? 'btn btn-primary' : 'btn btn-ligth'}
                      onClick={handleSwitchUrl}
                    >
                      Admin
                    </button>
                  </div>
                </div>
                <div className="col-md-6 col-12 tar">
                  <Link to="/pannel/notifications/new" className="btn btn-primary btn-radius">
                    <i className="fas fa-plus" />
                    {' New Notification'}
                  </Link>
                </div>

              </div>
            )
          }
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  {
                    columns.map((col) => (
                      <th>{col}</th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                {
                  notificationsList.count > 0
                    ? notificationsList.rows.map((notification) => (
                      mapRow(notification)
                    ))
                    : <tr><td colSpan={columns.length}>No entries found</td></tr>
                }
              </tbody>
            </table>

            <Pagination
              total={notificationsList.count}
              page={filterPage}
              limit={10}
              pageClick={handlePageClick}
              showLimit={false}
            />
          </div>
        </div>
      </div>
      <ModalCancelNotification
        show={showModalCancel}
        onHide={handleHideModal}
        onConfirm={cancelNotification}
      />
    </div>
  );
};

NotificationsList.propTypes = {
  showLoader: PropTypes.func,
  NotificationManager: PropTypes.object,
};

NotificationsList.defaultProps = {
  showLoader: (f) => f,
  NotificationManager: null,
};

export default NotificationsList;
