/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-undef */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import TodoElm from './todoElm';
import { fetchData, CreateAuthRequest } from '../../API/constants';
import CreateTree from './createTree';

// region ADD ELEMENT
const AddTodoElement = ({ onSubmit }) => {
  const [form, setForm] = React.useState({ name: '' });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(form.name);
    setForm({ name: '' });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <input
          name="name"
          className="form-control"
          value={form.name}
          onChange={(e) => setForm({ name: e.target.value })}
          placeholder="Type item name"
          required
        />
      </div>
    </form>
  );
};

AddTodoElement.propTypes = {
  onSubmit: PropTypes.func,
};
AddTodoElement.defaultProps = {
  onSubmit: f => f,
};

// endregion

// TO-DO-LIST

const TodoList = ({
  elmList: list,
}) => {
  const [items, setItems] = React.useState([]);
  const [showAdd, setshow] = React.useState(false);
  const [elmList, setElementList] = React.useState({});
  const [draggable, setDraggable] = React.useState(null);
  const [dragingOverElm, setDraggingOverElm] = React.useState(null);

  React.useEffect(() => {
    const newList = CreateTree(list.list_items);
    setElementList({ ...list, list_items: newList.list_items });
    setItems(newList.list_items.sort((a, b) => a.order - b.order));
  }, [list]);

  const UpdateList = async () => {
    const request = await CreateAuthRequest('GET', null, true);
    fetchData(`tasks/lists/${elmList.id_list}`, request)
      .then(response => {
        const newList = CreateTree(response.task_items);
        setElementList({ ...response, list_items: newList.list_items });
        setItems(newList.list_items.sort((a, b) => a.order - b.order));
      });
  };

  const handleShowAddInput = () => { setshow(true); };

  const handleAddNewSubtask = async (name) => {
    const request = await CreateAuthRequest('POST', { name, id_list: elmList.id_list }, true);
    fetchData('tasks/lists/items', request)
      .then(response => {
        setItems([...items, response]);
      });
  };

  const handleEditItem = async (idItem, body) => {
    const request = await CreateAuthRequest('PUT', body, true);
    fetchData(`tasks/lists/items/${idItem}`, request)
      .then(response => {
        setItems(items.map(i => (i.id_list_item === idItem ? { ...response } : i)));
        setTimeout(() => {
          UpdateList();
        }, 100);
      });
  };

  const handleIndent = (item, parent) => {
    let newParent = null;
    if (parent) {
      newParent = parent.list_items.find(i => (i.id_parent_item === item.id_parent_item && i.order === (item.order - 1)));
    } else {
      newParent = items.find(i => (i.id_parent_item === item.id_parent_item && i.order === (item.order - 1)));
    }

    if (newParent) {
      handleEditItem(item.id_list_item, {
        id_parent_item: newParent.id_list_item,
        order: 999999,
      });
      // handleEditItem(10, { id_parent_item: null, order: 9999 });
    }
  };

  const handleOutdent = (item, parent) => {
    if (parent) {
      handleEditItem(item.id_list_item, { id_parent_item: parent.id_parent_item, order: 999999 });
    }
    handleEditItem(item.id_list_item, { id_parent_item: null, order: 999999 });
  };

  const dropDrag = (e) => {
    e.stopPropagation();

    const id = dragingOverElm.id_parent_item;
    if (draggable.id_list_item !== id) {
      handleEditItem(draggable.id_list_item, { id_parent_item: id, order: dragingOverElm.order });
    }
    setDraggable(null);
    setDraggingOverElm(null);
  };

  const getPorcentage = () => {
    if (items.length === 0) {
      return 0;
    }
    const done = items.reduce((accumulator, currente) => accumulator + (currente.done ? 1 : 0), 0);
    const porcentaje = (done * 100) / items.length;
    return porcentaje;
  };

  return (
    <div>
      <div className="todo-header">
        <div className="row">
          <div className="col-6">
            <label>{elmList.name}</label>
          </div>
          <div className="col-6">
            <div className="progress" style={{ marginTop: '1em' }}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${getPorcentage()}%` }}
                aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        id={`list-${list.id_list_item}`}
        className="body-todo list-root"
        onDrop={dropDrag}
        onDragOver={(e) => { e.preventDefault(); }}
      >
        <div className="add-subtask">
          <button
            type="button"
            className="btn btn-link"
            onClick={handleShowAddInput}
          >
            <i className="fas fa-plus" />
            {' New item'}
          </button>

          {
            showAdd
            && <AddTodoElement onSubmit={handleAddNewSubtask} />
          }
        </div>
        {
          items.map(item => (
            <TodoElm
              key={item.id_list_item}
              idTask={elmList.id_task}
              elm={item}
              handleEditItem={handleEditItem}
              handleIndent={handleIndent}
              handleOutdent={handleOutdent}
              draggable={draggable}
              setDraggable={setDraggable}
              dropDrag={dropDrag}
              dragingOverElm={dragingOverElm}
              setDraggingOverElm={setDraggingOverElm}
            />
          ))
        }
      </div>
    </div>
  );
};

TodoList.propTypes = {
  elmList: PropTypes.object,
};

TodoList.defaultProps = {
  elmList: {
    id_list: 0,
    list_items: [],
  },
};

export default TodoList;
