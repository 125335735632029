/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt, faHome, faRoad, faCity, faMapMarkedAlt, faTrafficLight,
} from '@fortawesome/free-solid-svg-icons';
import useDebounce from '../../hooks/useDebounce';
import useClickOutside from '../../hooks/useClickOutside';
import { fetchData, CreateAuthRequest, makeUrlGET } from '../../API/constants';
import styles from './azurePlacesWithMap.module.css';
import StaticMap from './staticMap';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_QUERY':
      return { ...state, query: action.payload, cursor: -1 };
    case 'SET_CURSOR':
      return { ...state, cursor: action.payload };
    case 'SET_SUGGESTIONS':
      return { ...state, suggestions: action.payload };
    case 'SET_PLACE':
      return { ...state, place: action.payload };
    case 'SET_PLACENAME':
      return { ...state, placeName: action.payload };
    case 'SET_MAP':
      return { ...state, map: action.payload };
    case 'SET_POSITION':
      return { ...state, position: action.payload };
    default:
      return { ...state };
  }
};

const AzurePlacesWithMap = ({
  placeholder, reduxPlace,
  handleInputChange, name, value, label,
}) => {
  const initialState = {
    query: value ? value.address : '',
    cursor: -1,
    suggestions: [],
    place: null,
    placeName: value ? value.address : null,
    position: null,
  };

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const {
    query, cursor, suggestions, place, placeName, position,
  } = state;

  React.useEffect(() => {
    if (value) {
      if (typeof value.address === 'string') {
        dispatch({ type: 'SET_PLACENAME', payload: value.address });
        dispatch({ type: 'SET_QUERY', payload: value.address });
      }
    }
  }, [value]);

  const debouncedQuery = useDebounce(query, 300);
  const suggestionsRef = React.useRef();
  // const api = fetchData();

  const clickRef = useClickOutside(suggestions.length > 0, () => {
    dispatch({ type: 'SET_QUERY', payload: '' });
    dispatch({ type: 'SET_SUGGESTIONS', payload: [] });
  });

  const searchPlace = React.useCallback(async newQuery => {
    try {
      const request = await CreateAuthRequest('GET', null, true);
      await fetchData(`places/fuzzy?${makeUrlGET({
        query: newQuery,
        limit: 5,
        idxSet: 'PAD,POI',
      })}`, request)
        .then(response => {
          dispatch({ type: 'SET_SUGGESTIONS', payload: response.results });
        });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const searchPlaceByCoordenate = React.useCallback(async () => {
    const request = await CreateAuthRequest('GET', null, true);
    fetchData(`places/reverse?query=${`${position[0]},${position[1]}`}`, request)
      .then(response => {
        if (response.addresses[0].address.freeformAddress) {
          const obj = {
            position: { lat: position[0], lon: position[1] },
            address: response.addresses[0].address,
          };
          dispatch({ type: 'SET_PLACE', payload: obj });
          dispatch({ type: 'SET_QUERY', payload: obj.address.freeformAddress });
          handleInputChange({
            target: {
              name,
              value: obj,
            },
            type: 'change',
          });
        }
      });
  }, [position]);

  React.useEffect(() => {
    if (position) {
      searchPlaceByCoordenate();
    }
  }, [position, searchPlaceByCoordenate]);

  const pickFromMap = (obj) => {
    dispatch({ type: 'SET_POSITION', payload: [obj.latlng.lat, obj.latlng.lng] });
    // searchPlaceByCoordenate();
  };

  React.useEffect(() => {
    if (query !== debouncedQuery || debouncedQuery === placeName) {
      return;
    }
    if (debouncedQuery.length < 4) {
      dispatch({ type: 'SET_SUGGESTIONS', payload: [] });
      dispatch({ type: 'SET_PLACE', payload: null });
      // dispatch({ type: 'SET_PLACENAME', payload: null });
    } else {
      searchPlace(debouncedQuery);
    }
  }, [query, debouncedQuery, searchPlace, placeName]);

  React.useEffect(() => {
    if (place && place.type === 'POI') {
      dispatch({ type: 'SET_QUERY', payload: place.poi.name });
      dispatch({ type: 'SET_PLACENAME', payload: place.poi.name });
    } else if (place) {
      dispatch({ type: 'SET_QUERY', payload: place.address.freeformAddress });
      dispatch({ type: 'SET_PLACENAME', payload: place.address.freeformAddress });
    }
    dispatch({ type: 'SET_SUGGESTIONS', payload: [] });
  }, [place]);

  React.useEffect(() => {
    dispatch({ type: 'SET_PLACE', payload: reduxPlace });
  }, [reduxPlace]);

  const handleQueryChange = event => {
    const { value: newValue } = event.target;
    dispatch({ type: 'SET_QUERY', payload: newValue });
  };

  const handleKeys = event => {
    if (suggestions.length === 0 || !suggestionsRef.current) {
      return;
    }
    const ul = suggestionsRef.current;
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        if (cursor === -1) {
          ul.childNodes[0].focus();
          dispatch({ type: 'SET_CURSOR', payload: 0 });
        } else if (cursor + 1 < ul.childNodes.length) {
          ul.childNodes[cursor + 1].focus();
          dispatch({ type: 'SET_CURSOR', payload: prevState => prevState + 1 });
        }
        break;
      case 'ArrowUp':
        event.preventDefault();
        if (cursor > 0) {
          if (cursor - 1 >= 0) {
            ul.childNodes[cursor - 1].focus();
            dispatch({ type: 'SET_CURSOR', payload: prevState => prevState - 1 });
          }
        }
        break;
      case 'Enter':
        if (cursor !== -1) {
          ul.childNodes[cursor].click();
        }
        break;
      default:
        break;
    }
  };

  const handleBlur = () => {
    if (place) {
      // TODO: se arruina si te mueves a ver suggestions
      dispatch({ type: 'SET_QUERY', payload: placeName });
    }
  };

  const onPlaceSelect = newPlace => {
    dispatch({ type: 'SET_PLACE', payload: newPlace });
    handleInputChange({
      target: {
        name,
        value: newPlace,
      },
      type: 'change',
    });
  };

  const getPosition = React.useCallback(() => {
    let position = null;
    if (value) {
      if (value.position) {
        position = [parseFloat(value.position.lat), parseFloat(value.position.lon)];
      } else {
        position = [parseFloat(value.latitude), parseFloat(value.longitude)];
      }
    }
    return position;
  }, [value]);

  return (
    <>
      <div data-testid="AzurePlaces" ref={clickRef} className="col-6 wafo-wrapper">
        <div className={`${styles['input-wrapper']}  wafo-input form-group`}>
          {label && <label htmlFor="">{label}</label>}
          <div className={styles.input}>
            <input
              type="text"
              className="form-control"
              placeholder={placeholder}
              value={query}
              onChange={handleQueryChange}
              onKeyDown={handleKeys}
              onBlur={handleBlur}
              onClick={e => e.target.select()}
            />
          </div>
          {/* <button type="button" className={styles.crosshair} onClick={onCrosshairs}>
            <FontAwesomeIcon icon={faCrosshairs} />
          </button> */}
        </div>
        {suggestions.length > 0 && (
          <Suggestions
            ref={suggestionsRef}
            suggestions={suggestions}
            handleKeys={handleKeys}
            onSelect={onPlaceSelect}
          />
        )}
      </div>
      <div className="col-6">
        <StaticMap
          initialPos={[32.299507, -64.790337]}
          positionProp={getPosition()}
          handleOnClick={pickFromMap}
        />
      </div>
    </>
  );
};

AzurePlacesWithMap.propTypes = {
  placeholder: PropTypes.string,
  icon: PropTypes.any,
  iconColor: PropTypes.string,
  handleInputChange: PropTypes.func,
  reduxPlace: PropTypes.any,
  onCrosshairs: PropTypes.func,
  label: PropTypes.string,
};

AzurePlacesWithMap.defaultProps = {
  placeholder: 'El placeholder',
  icon: null,
  iconColor: '#fff',
  handleInputChange: f => f,
  reduxPlace: null,
  onCrosshairs: f => f,
};

export default AzurePlacesWithMap;

/** **************************************** */
/** Suggestions list */
/** **************************************** */

const placeTypes = {
  POI: {
    text: 'Punto de interés',
    icon: faMapMarkerAlt,
  },
  Street: {
    text: 'Calle',
    icon: faRoad,
  },
  Geography: {
    text: 'Entidad',
    icon: faCity,
  },
  'Point Address': {
    text: 'Domicilio',
    icon: faHome,
  },
  'Address Range': {
    text: 'Rango de dirección',
    icon: faMapMarkedAlt,
  },
  'Cross Street': {
    text: 'Cruce de calles',
    icon: faTrafficLight,
  },
};

/* eslint-disable react/display-name */
const Suggestions = React.forwardRef(({ suggestions, handleKeys, onSelect }, ref) => {
  const list = suggestions.map(place => (
    <li key={place.id} tabIndex="-1" onKeyDown={handleKeys} onClick={() => onSelect(place)}>
      <div className={styles['list-icon']}>
        <FontAwesomeIcon icon={placeTypes[place.type].icon} />
      </div>
      <div className={styles['list-text']}>
        {place.type === 'POI' && <span className={styles.poi}>{place.poi.name}</span>}
        <span className={styles.address}>{place.address.freeformAddress}</span>
        <span className={styles.type}>
          Tipo:
          {placeTypes[place.type].text}
        </span>
      </div>
    </li>
  ));

  return (
    <div className={`${styles.suggestions} mirai-shadow`}>
      <ul ref={ref}>{list}</ul>
    </div>
  );
});
/* eslint-enable react/display-name */

Suggestions.propTypes = {
  suggestions: PropTypes.array,
  handleKeys: PropTypes.func,
  onSelect: PropTypes.func,
};

Suggestions.defaultProps = {
  suggestions: [],
  handleKeys: f => f,
  onSelect: f => f,
};
