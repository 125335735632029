/* eslint-disable arrow-parens */
import { connect } from 'react-redux';
import { setShowLoader } from '../../Reducers/loaderReducer';

const loaderConnect = (component) => (
  connect(
    () => ({}),
    dispatch => ({
      showLoader: (status) => dispatch(setShowLoader(status)),
    }),
  )(component)
);

export default loaderConnect;
