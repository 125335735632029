/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

const MyEvent = ({ event }) => {
  const {
    title, day, start, status, url, date,
  } = event;
  return (
    <div className="rc-event-container" style={{ height: '100%', padding: '1px 6px', borderRadius: '4px', backgroundColor: `#${status.color}` }}>
      <Link to={url}>
        {/* <div className="circle-status" style={{ backgroundColor: `#${status.color}` }} /> */}
        <label title={`${title} ${date}`} style={{ color: '#fff' }}>
          {/* <small>{moment(start).tz('Atlantic/Bermuda').format('h:mm a')}</small>
          {' '} */}
          <b>{title}</b>
          {/* {day} */}
        </label>
      </Link>
    </div>
  );
};

MyEvent.propTypes = {
  event: PropTypes.object.isRequired,
};

MyEvent.defaultPrps = {
  event: { title: '', day: '' },
};

export default MyEvent;
