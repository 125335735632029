/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Link } from 'react-router-dom';
// import jwt from 'jwt-decode';
// import { SidebarContext } from '../../context/sidebar/sidebarContext';
import { ImageUser } from '../../components/ShowUsers/usersList';
import useClickOutside from '../../hooks/useClickOutside';
import { UserContext } from '../../context/userContext/userContext';

const TopBar = () => {
  // const user = jwt(sessionStorage.getItem('token'));
  // const { sidebar, updateSidebar } = React.useContext(SidebarContext);
  const [displayDropdown, setDisplayDropdown] = React.useState(false);
  const { user } = React.useContext(UserContext);
  const signOut = () => {
    localStorage.clear();
    sessionStorage.clear();
  };


  const handleShowDropdownMenu = () => {
    setDisplayDropdown(!displayDropdown);
  };

  const clickRef = useClickOutside(displayDropdown, () => {
    setDisplayDropdown(false);
  });

  return (
    <nav className="navbar fixed-top navbar-expand-lg navbar-light ">
      <Link className="navbar-brand" to="/pannel/tasks">
        <label style={{ cursor: 'pointer' }}>Power of one</label>
      </Link>

      <ul className="navbar-nav ml-auto nav-flex-icons">

        <li className="nav-item dropdown" ref={clickRef}>
          <button onClick={handleShowDropdownMenu} type="button" className="nav-link dropdown-toggle btn " id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <ImageUser userName={`${user.name} ${user.last_name}`} urlImage={user.image_url} />
            {`${user.name} ${user.last_name}`}
          </button>
          <div className={`dropdown-menu ${displayDropdown ? 'display' : ''}`} aria-labelledby="navbarDropdown">
            <Link className="dropdown-item" to={`/pannel/users/profile/${user.id_user}`} onClick={() => setDisplayDropdown(false)}>
              <i className="fas fa-user" />
              {' My profile'}
            </Link>
            <Link className="dropdown-item" to="/" onClick={signOut}>
              <i className="fas fa-power-off" />
              {' Sign out'}
            </Link>
          </div>
        </li>
      </ul>
    </nav>
  );
};
export default TopBar;
