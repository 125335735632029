/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation, Link } from 'react-router-dom';
import {
  fetchData, CreateAuthRequest, makeUrlGET,
} from '../../API/constants';
import InputDebounce from '../../components/InputDebounce/inputDebounce';
// import Typeahead from '../../components/CustomizedTypeahead/typeahead';
import Pagination from '../../components/Pagination/pagination';
import './users.scss';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_USERS_LIST':
      return { ...state, usersList: action.payload };
    case 'SET_FILTER_SEARCH':
      return { ...state, filterSearch: action.payload };
    case 'SET_FILTER_SECTION':
      return { ...state, filterSection: action.payload };
    case 'SET_FILTER_TYPE':
      return { ...state, filterType: action.payload };
    case 'SET_FILTER_PAGE':
      return { ...state, filterPage: action.payload };
    case 'SET_FILTER_ACTIVE':
      return { ...state, filterActive: action.payload };
    case 'RESET_FILTERS':
      return {
        ...state,
        filterSearch: '',
        filterSection: '',
        filterType: '',
        filterPage: 1,
        filterActive: '',
      };
    default:
      return { ...state };
  }
};

const UsersList = ({ showLoader }) => {
  const history = useHistory();
  const location = useLocation();
  const initialState = {
    usersList: { count: 0, rows: [] },
    filterSearch: '',
    filterSection: null,
    filterType: '',
    filterActive: '',
    filterPage: location.state ? location.state.page : 1,
  };

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const {
    usersList,
    filterSearch,
    filterSection,
    filterType,
    filterPage,
    filterActive,
  } = state;

  const getUsersList = React.useCallback(async () => {
    showLoader(true);
    const url = `users?limit=10&${makeUrlGET({
      page: filterPage,
      search: filterSearch,
      id_section: filterSection,
      type: filterType,
      disabled: filterActive,
    })}`;
    const request = await CreateAuthRequest('GET', null, true);
    fetchData(url, request).then((response) => {
      dispatch({ type: 'SET_USERS_LIST', payload: response });
    })
      .finally(() => { showLoader(false); });
  }, [filterSearch, filterType, filterSection, filterPage, filterActive]);

  React.useEffect(() => {
    getUsersList();
  }, [getUsersList]);

  const handleChangeTypeFilter = ({ target }) => {
    dispatch({ type: 'SET_FILTER_TYPE', payload: target.value });
  };

  const handleChangeSectionFilter = (value) => {
    dispatch({ type: 'SET_FILTER_SECTION', payload: value });
  };

  const handleSearchByName = (text) => {
    dispatch({ type: 'SET_FILTER_SEARCH', payload: text });
  };

  const handleDetail = (user) => {
    const helperLocation = {
      pathname: `${location.pathname}`,
      state: { filterPage },
    };
    history.replace(helperLocation);
    history.push(`/pannel/users/details/${user.id_user}`);
  };

  const handlePageClick = (page) => {
    dispatch({ type: 'SET_FILTER_PAGE', payload: page });
  };

  const handleFilterActive = ({ target }) => {
    dispatch({ type: 'SET_FILTER_ACTIVE', payload: target.value });
  };

  const getTypeUser = (type) => {
    switch (type) {
      case 1:
        return 'Admin';
      case 2:
        return 'Supervisor';
      case 3:
        return 'Employee';
      default:
        return 'Not defined';
    }
  };

  return (
    <div className="users-list">
      <div className="row">
        <div className="col-12 col-md-6">
          <InputDebounce
            placeholder="Search user by name"
            handleInputChange={handleSearchByName}
            value={filterSearch}
          />
        </div>
        {/* <div className="col-12 col-md-3">
          <Typeahead
            labelKey="name"
            url={`${process.env.REACT_APP_API_URL}sections`}
            multiple={false}
            minLength={3}
            onChange={(option) => handleChangeSectionFilter(option[0]?.id_section)}
            placeholder="Filter by section..."
            itemChildren={(option) => (
              <span>{`${option.id_section} ${option.name}`}</span>
            )}
            inputProps={{ required: 'required' }}
          />
        </div> */}
        <div className="col-12 col-md-3">
          <select className="form-control input-shadow" value={filterType} onChange={handleChangeTypeFilter}>
            <option value="">All types</option>
            <option value={1}>Admin</option>
            <option value={2}>Supervisor</option>
            <option value={3}>Employee</option>
          </select>
        </div>
        <div className="col-12 col-md-3">
          <select className="form-control input-shadow" value={filterActive} onChange={handleFilterActive}>
            <option value="">Active/Inactive</option>
            <option value="0">Active</option>
            <option value="1">Inactive</option>
          </select>
        </div>
      </div>
      <div className="card mg-top">
        <div className="card-body">
          <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
            <h3>Users</h3>
            <Link to="/pannel/users/new" className="btn btn-primary btn-radius">
              <i className="fas fa-plus" />
              {' User'}
            </Link>
          </div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Type</th>
                  <th className="tac">Options</th>
                </tr>
              </thead>
              <tbody>
                {
                  usersList.count > 0
                    ? usersList.rows.map((user) => (
                      <tr key={user.id_user}>
                        <td>{`${user.name} ${user.last_name}`}</td>
                        <td>{user.email}</td>
                        <td>{getTypeUser(user.type)}</td>
                        <td className="tac">
                          <button type="button" className="btn btn-primary btn-sm btn-radius" onClick={() => handleDetail(user)}>
                            <i className="fas fa-ellipsis-h" />
                          </button>
                        </td>
                      </tr>
                    ))
                    : <tr><td colSpan="4">No entries found</td></tr>
                }
              </tbody>
            </table>

            <Pagination
              total={usersList.count}
              page={filterPage}
              limit={10}
              pageClick={handlePageClick}
              showLimit={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

UsersList.propTypes = {
  showLoader: PropTypes.func,
  // NotificationManager: object,
};

UsersList.defaultProps = {
  showLoader: (f) => f,
  // NotificationManager: null,
};

export default UsersList;
