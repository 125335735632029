/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import TodoList from './todolist';
import { fetchData, CreateAuthRequest } from '../../API/constants';
import './todolist.scss';

const AddTodoList = ({ onSubmit }) => {
  const [form, setForm] = React.useState({ name: '' });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(form.name);
    setForm({ name: '' });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <input
          name="name"
          className="form-control input-shadow"
          value={form.name}
          onChange={(e) => setForm({ name: e.target.value })}
          placeholder="Type to-do list name"
          required
        />
      </div>
    </form>
  );
};

AddTodoList.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const TodoListWrapper = ({ idTask, update }) => {
  const [lists, setList] = React.useState({ count: 0, rows: [] });

  const getLists = React.useCallback(async () => {
    const request = await CreateAuthRequest('GET', null, true);
    fetchData(`tasks/lists/?id_task=${idTask}`, request)
      .then(response => {
        setList(response);
      });
  }, [idTask, update]);

  React.useEffect(() => {
    getLists();
  }, [getLists]);

  const handleAddNewList = async (name) => {
    const request = await CreateAuthRequest('POST', { name, id_task: idTask }, true);
    fetchData('tasks/lists/', request)
      .then(response => {
        setList({
          count: lists.console + 1,
          rows: [...lists.rows, { ...response, list_items: response.task_items }],
        });
      });
  };

  return (
    <div className="todo-list">
      <label>To-do lists</label>
      <div>
        <label><i className="fas fa-plus" /> Add to-do list</label>
        <AddTodoList onSubmit={handleAddNewList} />
      </div>

      {
        lists.rows.map(elm => (
          <TodoList
            key={elm.id_list + Math.random()}
            elmList={elm}
            handleUpdate={() => { }}
          />
        ))
      }
    </div>
  );
};

TodoListWrapper.propTypes = {
  idTask: PropTypes.string,
  update: PropTypes.number,
};

TodoListWrapper.defaultProps = {
  idTask: 0,
  update: 0,
};

export default TodoListWrapper;
