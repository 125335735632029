/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  fetchData, CreateAuthRequest, makeUrlGET,
} from '../../API/constants';
import Typeahead from '../../components/CustomizedTypeahead/typeahead';
import InputDebounce from '../../components/InputDebounce/inputDebounce';
import Pagination from '../../components/Pagination/pagination';

const wikiTypes = [
  '',
  'Organization charts - uploading documentation',
  'Directory of the sections',
  'The roles of the section',
  'Sections vision',
  'Union agreements (CAB)',
  'Job Descriptions',
  'Health and safety documents (Incidents & Accidents reports and protocol to follow) - Uploading to employee´s portal',
];

const WikisList = ({ showLoader }) => {
  const history = useHistory();
  const location = useLocation();

  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState('');
  const [filterSection, setFilterSection] = React.useState(null);
  const [filterType, setFilterType] = React.useState('');
  const [filterActive, setFilterActive] = React.useState('0');
  const [wikisList, setWikisList] = React.useState({ count: 0, rows: [] });

  const getWikisList = React.useCallback(async () => {
    showLoader(true);
    const url = `sections/wikis?limit=10&${makeUrlGET({
      page,
      search,
      id_section: filterSection,
      type: filterType,
      disabled: filterActive,
    })}`;
    const request = await CreateAuthRequest('GET', null, true);
    fetchData(url, request).then((response) => {
      setWikisList(response);
    })
      .finally(() => { showLoader(false); });
  }, [search, page, filterSection, filterType, filterActive]);

  React.useEffect(() => {
    getWikisList();
  }, [getWikisList]);

  const handleSearchByName = (text) => {
    setSearch(text);
  };

  const handlePageClick = (newPage) => {
    setPage(newPage);
  };

  const handleDetail = (wiki) => {
    const helperLocation = {
      pathname: `${location.pathname}`,
      state: { page },
    };
    history.replace(helperLocation);
    history.push(`/pannel/wikis/details/${wiki.id_wiki}`);
  };

  const handleChangeSectionFilter = (idSection) => {
    setFilterSection(idSection);
  };

  const handleFilterTypeChange = ({ target }) => {
    setFilterType(target.value);
  };

  const handleFilterActive = ({ target }) => {
    setFilterActive(target.value);
  };

  return (
    <div className="users-list">
      <div className="row">
        <div className="col-12 col-md-4">
          <InputDebounce
            placeholder="Search wiki by title..."
            handleInputChange={handleSearchByName}
            value={search}
          />
        </div>
        <div className="col-12 col-md-4">
          <Typeahead
            labelKey="name"
            url={`${process.env.REACT_APP_API_URL}sections`}
            multiple={false}
            minLength={3}
            onChange={(option) => handleChangeSectionFilter(option[0]?.id_section)}
            placeholder="Filter by section..."
            itemChildren={(option) => (
              <span>{`${option.id_section} ${option.name}`}</span>
            )}
            inputProps={{ required: 'required' }}
          />
        </div>
        <div className="col-12 col-md-4">
          <select className="form-control input-shadow" name="type" value={filterType} onChange={handleFilterTypeChange} required>
            <option value="">All wiki types</option>
            <option value={1}>Organization charts - uploading documentation</option>
            <option value={2}>Directory of the sections</option>
            <option value={3}>The roles of the section</option>
            <option value={4}>Sections vision</option>
            <option value={5}>Union agreements (CAB)</option>
            <option value={6}>Job Descriptions</option>
            <option value={7}>Health and safety documents (Incidents & Accidents reports and protocol to follow) - Uploading to employee´s portal</option>
          </select>
        </div>
      </div>
      <div className="row mg-top">
        <div className="col-12 col-md-3">
          <select className="form-control input-shadow" value={filterActive} onChange={handleFilterActive}>
            <option value="">Active/Inactive</option>
            <option value="0">Active</option>
            <option value="1">Inactive</option>
          </select>
        </div>
      </div>
      <div className="card mg-top">
        <div className="card-body">
          <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
            <h3>Wiki</h3>
            <Link to="/pannel/wikis/new" className="btn btn-primary btn-radius">
              <i className="fas fa-plus" />
              {' New wiki'}
            </Link>
          </div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Title</th>
                  <th>Section</th>
                  <th>Type</th>
                  <th>Active</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                {
                  wikisList.count > 0
                    ? wikisList.rows.map((wiki) => (
                      <tr key={wiki.id_wiki}>
                        <td>{wiki.id_wiki}</td>
                        <td>{wiki.title}</td>
                        <td>{wiki.section.name}</td>
                        <td>{wikiTypes[wiki.type]}</td>
                        <td>{wiki.disabled ? 'Inactive' : 'Active'}</td>
                        <td className="tac">
                          <button type="button" className="btn btn-primary btn-sm btn-radius" onClick={() => handleDetail(wiki)}>
                            <i className="fas fa-ellipsis-h" />
                          </button>
                        </td>
                      </tr>
                    ))
                    : <tr><td colSpan="6">No entries found</td></tr>
                }
              </tbody>
            </table>

            <Pagination
              total={wikisList.count}
              page={page}
              limit={10}
              pageClick={handlePageClick}
              showLimit={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

WikisList.propTypes = {
  showLoader: PropTypes.func,
  // NotificationManager: object,
};

WikisList.defaultProps = {
  showLoader: (f) => f,
  // NotificationManager: null,
};

export default WikisList;
