/* eslint-disable arrow-parens */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import { baseUrl } from '../../API/constants';
import ModalConfirm from '../ModalConfirm/modalConfirm';
import jwtDecode from 'jwt-decode';

const FilesList = ({
  list, labelKey, idKey, handleDeleteFile,
}) => {
  const user = jwtDecode(sessionStorage.token);
  const getIcon = (fileName) => {
    const fileType = fileName.split('.').pop().toLowerCase();
    switch (fileType) {
      case 'pdf':
        return 'fas fa-file-pdf';
      case 'doc':
      case 'docx':
        return 'fas fa-file-word';
      case 'xls':
      case 'xlsx':
        return 'fas fa-file-excel';
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'svg':
      case 'gif':
        return 'fas fa-file-image';
      default:
        return 'fas fa-file';
    }
  };

  const [statuses, setStatuses] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [fileToDelete, setFileToDelete] = React.useState(null);

  React.useEffect(() => {
    let helperStatuses = [];
    if (list.length > 0) {
      for (let i = 0; i < list.length; i += 1) {
        const element = list[i];
        const stat = helperStatuses.find(s => s.id_status === element.id_status);
        if (stat) {
          helperStatuses = helperStatuses.map(s => {
            if ((s.id_status === element.id_status)) {
              return { ...s, files: [...s.files, element] };
            }
            return { ...s };
          });
        } else {
          helperStatuses.push({ ...element.status, files: [element] });
        }
        setStatuses(helperStatuses);
      }
    } else {
      setStatuses([]);
    }
    console.log('list change', list);
  }, [list]);

  const Download = async (fileObj) => {
    const name = fileObj.original_filename;
    const request = { method: 'GET' };

    fetch(baseUrl + fileObj.file_url, request)
      .then(async response => {
        if (!response.message) {
          const file = await response.blob();
          FileSaver.saveAs(file, name);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleHideModal = () => {
    setShowModal(false);
    setFileToDelete(null);
  };

  const handleShowModal = (file) => {
    setShowModal(true);
    setFileToDelete(file);
  };

  const confirmDelete = () => {
    handleDeleteFile(fileToDelete);
    handleHideModal();
  };

  return (
    <div className="files-list">
      {
        statuses.map(status => (
          <div className="" key={status.id_status || Math.random()}>
            <label
              className="badge"
              title={`Uploaded in status: ${status.name}`}
              style={{
                backgroundColor: `#${status.color || '222222'}`, color: '#fff', position: 'relative', bottom: '-12px', borderRadius: '20px',
              }}
            >
              {status.name || 'No status'}
            </label>
            <div style={{ height: '2px', backgroundColor: `#${status.color || '222222'}`, marginBottom: '21px' }} />
            {
              status.files.map((elm) => (
                <div className="file-elm" key={elm[idKey]}>
                  <div className="row">
                    <div className="col-9">
                      <i className={getIcon(elm[labelKey])} />
                      <label>{elm[labelKey]}</label>
                    </div>
                    <div className="col tar">
                      <button
                        type="button"
                        // href={baseUrl + elm.file_url}
                        // target="_blank"
                        // rel="noreferrer"
                        className="btnn"
                        onClick={() => Download(elm)}
                      >
                        <i className="fas fa-download" />
                      </button>
                      {
                        user.user_type === 1
                        && <button type="button" className="btnn delete" onClick={() => handleShowModal(elm)}><i className="fas fa-trash-alt" /></button>
                      }
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        ))
      }
      <ModalConfirm
        show={showModal}
        type="delete"
        message={fileToDelete && fileToDelete[labelKey]}
        onHide={handleHideModal}
        onConfirm={confirmDelete}
      />
    </div>
  );
};

FilesList.propTypes = {
  labelKey: PropTypes.string.isRequired,
  idKey: PropTypes.string.isRequired,
  list: PropTypes.array,
  handleDeleteFile: PropTypes.func,
};

FilesList.defaultProps = {
  list: [],
  handleDeleteFile: f => f,
};

export default FilesList;
