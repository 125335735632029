/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { SocketContext } from './socketContext';
import { showNotification } from '../../API/constants';

const SocketContextProvider = ({ children }) => {
  const [socketState, setSocketContext] = React.useState(new WebSocket(`${process.env.REACT_APP_WSS_URL}`));
  const [socketMessages, setSocketMessages] = React.useState([]);

  React.useEffect(() => {
    // socket = new WebSocket(`${process.env.REACT_APP_WSS}`);
    // setSocketContext(new WebSocket(`${process.env.REACT_APP_WSS_URL}`));
    // socket = new WebSocket(`${process.env.REACT_APP_WSS_URL}`);

    // Connection opened
    socketState.onopen = () => {
      socketState.send(JSON.stringify({
        action: 'authenticate',
        payload: sessionStorage.token,
      }));
    };

    // Listen for messages
    socketState.onmessage = (event) => {
      const eventJS = JSON.parse(event.data);

      // console.log('sockete', eventJS);

      if (eventJS.action === 'ping') {
        socketState.send(JSON.stringify({
          action: 'pong',
        }));
      }

      if (eventJS.action === 'log' || eventJS.action === 'error' || eventJS.action === 'serverClosing') {
        return;
      }

      if (eventJS.action === 'notification') {
        // getCounterNotification();
        showNotification(eventJS.payload.title, eventJS.payload.message);
      }

      if (eventJS.action === 'message' || eventJS.action === 'message/file') {
        setSocketMessages([eventJS]);
        if (eventJS.action === 'message') {
          if (eventJS.payload.message !== '') {
            showNotification(`${eventJS.payload.from_user.name} ${eventJS.payload.from_user.last_name}`, eventJS.payload.message, '/pannel/messages');
          } else {
            showNotification(`${eventJS.payload.from_user.name} ${eventJS.payload.from_user.last_name}`, 'File', '/pannel/messages');
          }
        }
      }
    };

    return () => {
      if (socketState) {
        socketState.close();
      }
    };
  }, []);

  const openSocket = () => {
    setSocketContext(new WebSocket(`${process.env.REACT_APP_WSS_URL}`));
    socketState.onopen = () => {
      socketState.send(JSON.stringify({
        action: 'authenticate',
        payload: sessionStorage.token,
      }));
    };
  };

  const send = ({ action, payload }) => {
    if (socketState.readyState !== 1) {
      socketState.send(JSON.stringify({
        action,
        payload,
      }));
    } else {
      setSocketContext(new WebSocket(`${process.env.REACT_APP_WSS_URL}`));
      socketState.onopen = () => {
        socketState.send(JSON.stringify({
          action: 'authenticate',
          payload: sessionStorage.token,
        }));
      };
      socketState.send(JSON.stringify({
        action,
        payload,
      }));
    }
  };

  return (
    <SocketContext.Provider value={{
      socket: socketState, send, openSocket, socketMessages, updateNewMessages: setSocketMessages,
    }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketContextProvider;
