/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import DateTime from 'react-datetime';
import {
  fetchData, CreateAuthRequest, makeUrlGET,
} from '../../API/constants';
import Typeahead from '../../components/CustomizedTypeahead/typeahead';
import InputDebounce from '../../components/InputDebounce/inputDebounce';

const today = moment();
const firstDayOfCurrentMonth = moment().startOf('month');

const WikisList = ({ showLoader }) => {
  const history = useHistory();
  // const location = useLocation();

  const [search, setSearch] = React.useState('');
  const [filterSection, setFilterSection] = React.useState(null);
  const [taskList, setTaskList] = React.useState({ count: 0, rows: [] });
  const [dateFilters, setDateFilters] = React.useState({ startDate: firstDayOfCurrentMonth, endDate: today });

  const getTaskList = React.useCallback(async () => {
    showLoader(true);
    const url = `tasks?limit=10&id_status=2&${makeUrlGET({
      search,
      id_section: filterSection,
      deadline_date: dateFilters && `${moment(dateFilters.startDate).tz('Atlantic/Bermuda').startOf('day').toISOString()}|${moment(dateFilters.endDate).tz('Atlantic/Bermuda').endOf('day').toISOString()}`,
    })}`;
    const request = await CreateAuthRequest('GET', null, true);
    fetchData(url, request).then((response) => {
      setTaskList(response);
    })
      .finally(() => { showLoader(false); });
  }, [search, filterSection, dateFilters]);

  React.useEffect(() => {
    getTaskList();
  }, [getTaskList]);

  const handleSearchByName = (text) => {
    setSearch(text);
  };

  const handleDetail = (task) => {
    // const helperLocation = {
    //   pathname: `${location.pathname}`,
    //   state: { page },
    // };
    // history.replace(helperLocation);
    if (task.id_task_parent) {
      history.push(`/pannel/tasks/detail/${task.id_task_parent}/subtask/${task.id_task}`);
    } else {
      history.push(`/pannel/tasks/detail/${task.id_task}`);
    }
  };

  const handleChangeSectionFilter = (idSection) => {
    setFilterSection(idSection);
  };

  const handleChangeDateFilter = ({ name, value }) => {
    const momentValue = moment(value);
    if (name === 'startDate') {
      if (momentValue.isAfter(moment(dateFilters.endDate))) {
        setDateFilters({ startDate: momentValue, endDate: momentValue });
      } else {
        setDateFilters({ ...dateFilters, [name]: momentValue });
      }
    } else if (momentValue.isBefore(moment(dateFilters.startDate))) {
      setDateFilters({ startDate: momentValue, endDate: momentValue });
    } else {
      setDateFilters({ ...dateFilters, [name]: momentValue });
    }
  };

  return (
    <div className="users-list">
      <div className="row">
        <div className="col-12 col-md-4">
          <InputDebounce
            placeholder="Search task by title..."
            handleInputChange={handleSearchByName}
            value={search}
          />
        </div>

        <div className="col-md-4 col-12">
          <div className="input-group input-shadow">
            <div className="form-control" style={{ padding: '0', border: '0', backgroundColor: '#fff' }}>
              <DateTime
                closeOnSelect
                dateFormat="DD/MMM/YYYY"
                timeFormat={false}
                onChange={(dateMoment) => handleChangeDateFilter({ name: 'startDate', value: moment(dateMoment) })}
                value={moment(dateFilters.startDate).format('DD/MMM/YYYY')}
                inputProps={{ placeholder: 'Filter by due date', readOnly: true, style: { backgroundColor: '#fff' } }}
              />
            </div>
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">@</span>
            </div>
            <div className="form-control" style={{ padding: '0', border: '0', backgroundColor: '#fff' }}>
              <DateTime
                closeOnSelect
                dateFormat="DD/MMM/YYYY"
                timeFormat={false}
                onChange={(dateMoment) => handleChangeDateFilter({ name: 'endDate', value: moment(dateMoment) })}
                value={moment(dateFilters.endDate).format('DD/MMM/YYYY')}
                inputProps={{ placeholder: 'Filter by due date', readOnly: true, style: { backgroundColor: '#fff' } }}
              />
            </div>
          </div>

        </div>

        <div className="col-12 col-md-4">
          <Typeahead
            labelKey="name"
            url={`${process.env.REACT_APP_API_URL}sections`}
            multiple={false}
            minLength={3}
            onChange={(option) => handleChangeSectionFilter(option[0]?.id_section)}
            placeholder="Filter by section..."
            itemChildren={(option) => (
              <span>{`${option.id_section} ${option.name}`}</span>
            )}
            inputProps={{ required: 'required' }}
          />
        </div>

      </div>
      <div className="card mg-top">
        <div className="card-body">
          <div>
            <h3>Tasks History</h3>
          </div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Title</th>
                  <th>Section</th>
                  <th>Due date</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                {
                  taskList.count > 0
                    ? taskList.rows.map((task) => (
                      <tr key={task.id_task}>
                        <td>{task.id_task}</td>
                        <td>{task.title}</td>
                        <td>{task.section.name}</td>
                        <td>{moment(task.deadline_date).format('DD/MMM/YYYY')}</td>
                        <td className="tac">
                          <button type="button" className="btn btn-primary btn-sm btn-radius" onClick={() => handleDetail(task)}>
                            <i className="fas fa-ellipsis-h" />
                          </button>
                        </td>
                      </tr>
                    ))
                    : <tr><td colSpan="5">No entries found</td></tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

WikisList.propTypes = {
  showLoader: PropTypes.func,
  // NotificationManager: object,
};

WikisList.defaultProps = {
  showLoader: (f) => f,
  // NotificationManager: null,
};

export default WikisList;
