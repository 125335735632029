/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

const SideBar = () => {
  const user = jwtDecode(sessionStorage.token);

  return (
    <div id="sidebar-wrapper">
      <ul className="sidebar-nav">
        <li>
          <NavLink to="/pannel/tasks" title="Tasks">
            <i className="fas fa-list" />
            {' '}
            Tasks
          </NavLink>
        </li>
        <li>
          <NavLink to="/pannel/calendar" title="Calendar">
            <i className="fas fa-calendar-alt" />
            {' '}
            Calendar
          </NavLink>
        </li>
        <li>
          <NavLink to="/pannel/messages" title="Messages">
            <i className="fas fa-comments" />
            {' '}
            Messages
          </NavLink>
        </li>
        <li>
          <NavLink to="/pannel/notifications" title="Notifications">
            <i className="fas fa-bell" />
            {' '}
            Notifications
            {' '}
          </NavLink>
        </li>
        {
          (user.user_type === 1 || user.user_type === 2)
          && (
            <>
              <li>
                <NavLink to="/pannel/history" title="Tasks history">
                  <i className="fas fa-list" />
                  {' Tasks History'}
                </NavLink>
              </li>
              <li>
                <NavLink to="/pannel/jobs" title="Job Posting">
                  <i className="fas fa-user-tie" />
                  {' Job Posting'}
                </NavLink>
              </li>
              <li>
                <NavLink to="/pannel/wikis" title="Job Posting">
                  <i className="fas fa-user-tie" />
                  {' Wiki'}
                </NavLink>
              </li>
              <li>
                <NavLink to="/pannel/sections" title="Sections">
                  <i className="fas fa-building" />
                  {' Sections'}
                </NavLink>
              </li>
              <li>
                <NavLink to="/pannel/ministries" title="Ministries">
                  <i className="fas fa-building" />
                  {' Ministries'}
                </NavLink>
              </li>

              {/* <li>
                <NavLink to="/pannel/map" title="Users">
                  <i className="fas fa-map-marked-alt" />
                  {' Map'}
                </NavLink>
              </li> */}
            </>
          )
        }
        {
          user.user_type === 1
          && (
            <>
              <li>
                <NavLink to="/pannel/reports" title="Users">
                  <i className="fas fa-chart-bar" />
                  {' Reports'}
                </NavLink>
              </li>
              <li>
                <NavLink to="/pannel/users" title="Users">
                  <i className="fas fa-users" />
                  {' Users'}
                </NavLink>
              </li>
            </>
          )
        }

      </ul>
    </div>
  );
};

export default withRouter(SideBar);
