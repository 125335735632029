/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { statuses, StatusesContext } from './statusesContext';

const StatusesContextProvider = ({ children }) => {
  const [state, setStatusesContext] = React.useState(statuses);

  return (
    <StatusesContext.Provider value={{ statuses: state, updateStatuses: setStatusesContext }}>
      {children}
    </StatusesContext.Provider>
  );
};

StatusesContextProvider.propTypes = {
  children: PropTypes.object,
};

StatusesContextProvider.defaultProps = {
  children: null,
};

export default StatusesContextProvider;
