/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  fetchData, CreateAuthRequest, makeUrlGET,
} from '../../API/constants';
import InputDebounce from '../../components/InputDebounce/inputDebounce';
import Pagination from '../../components/Pagination/pagination';

const SectionsList = ({ showLoader }) => {
  const history = useHistory();
  const location = useLocation();

  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState('');
  const [sectionsList, setSectionsList] = React.useState({ count: 0, rows: [] });

  const getSectionsList = React.useCallback(async () => {
    showLoader(true);
    const url = `sections?limit=10&${makeUrlGET({
      page,
      search,
    })}`;
    const request = await CreateAuthRequest('GET', null, true);
    fetchData(url, request).then((response) => {
      setSectionsList(response);
    })
      .finally(() => { showLoader(false); });
  }, [search, page]);

  React.useEffect(() => {
    getSectionsList();
  }, [getSectionsList]);

  const handleSearchByName = (text) => {
    setSearch(text);
  };

  const handlePageClick = (newPage) => {
    setPage(newPage);
  };

  const handleDetail = (section) => {
    const helperLocation = {
      pathname: `${location.pathname}`,
      state: { page },
    };
    history.replace(helperLocation);
    history.push(`/pannel/sections/edit/${section.id_section}`);
  };

  return (
    <div className="users-list">
      <div className="row">
        <div className="col-12 col-md-6">
          <InputDebounce
            placeholder="Search section by name"
            handleInputChange={handleSearchByName}
            value={search}
          />
        </div>
      </div>
      <div className="card mg-top">
        <div className="card-body">
          <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
            <h3>Sections</h3>
            <Link to="/pannel/sections/new" className="btn btn-primary btn-radius">
              <i className="fas fa-plus" />
              {' New Section'}
            </Link>
          </div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Contact person</th>
                  <th>Phone number</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                {
                  sectionsList.count > 0
                    ? sectionsList.rows.map((section) => (
                      <tr key={section.id_section}>
                        <td>{section.id_section}</td>
                        <td>{section.name}</td>
                        <td>{section.contact_person ? section.contact_person : 'N/A'}</td>
                        <td>{section.phone_number ? section.phone_number : 'N/A'}</td>
                        <td className="tac">
                          <button type="button" className="btn btn-primary btn-sm  btn-radius" onClick={() => handleDetail(section)}>
                            <i className="fas fa-ellipsis-h" />
                          </button>
                        </td>
                      </tr>
                    ))
                    : <tr><td colSpan="5">No entries found</td></tr>
                }
              </tbody>
            </table>

            <Pagination
              total={sectionsList.count}
              page={page}
              limit={10}
              pageClick={handlePageClick}
              showLimit={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

SectionsList.propTypes = {
  showLoader: PropTypes.func,
  // NotificationManager: object,
};

SectionsList.defaultProps = {
  showLoader: (f) => f,
  // NotificationManager: null,
};

export default SectionsList;
