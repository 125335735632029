/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-useless-escape */
import React from 'react';
import PropTypes from 'prop-types';
import { useParams, Link, useHistory } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { fetchData } from '../../API/constants';
import loaderConnect from '../../components/Loader/loaderConnect';
import Loader from '../../components/Loader/loader';
import './login.scss';

const ShowPasswordMessage = ({ match }) => {
  const message = match ? 'Passwords match.' : 'Passwords unmatch.';
  let label = '';
  if (match !== undefined) {
    label = (
      <label className={match ? 'match' : 'unmatch'}>
        <i className={match ? 'fas fa-check' : 'fas fa-times'} />
        {' '}
        {message}
      </label>
    );
  }
  return label;
};

const FalseEmail = ({ email }) => (
  <div className="card">
    <div className="logo tac">
      <h3 className="brand">Power of one</h3>
    </div>
    <div className="card-body">
      <div className="tac">
        <label className="welcome">Change password</label>
      </div>
      <div className="separate">
        <h4>
          {'Ups! email '}
          <b className="bold">{email}</b>
          {' it is not valid.'}
        </h4>
        <Link to="/login">Sign in here.</Link>
      </div>
    </div>
  </div>
);

const ResetPassword = ({ showLoader }) => {
  const history = useHistory();
  const [state, setState] = React.useState({
    password: '',
    confirmPassword: '',
    match: undefined,
    email: '',
    emailValid: false,
    code: null,
  });

  const [showPswd, setShowPswd] = React.useState(false);
  const [showconfirmPswd, setShowConformPswd] = React.useState(false);

  const { password, confirmPassword, match, emailValid, code } = state;

  const { userEmail, token } = useParams();

  React.useEffect(() => {
    function Valid(eMail) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      console.log('test', re.test((`${eMail}`).toLowerCase()));
      return re.test((`${eMail}`).toLowerCase());
    }

    if (userEmail && token) {
      if (Valid(userEmail)) {
        setState({ ...state, emailValid: true, email: userEmail, code: token });
      } else {
        setState({ ...state, emailValid: false, email: userEmail });
      }
    }
  }, [userEmail, token]);

  const Submit = (e) => {
    e.preventDefault();
    if (e.target.checkValidity() && match) {
      showLoader(true);
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      const request = {
        method: 'POST',
        headers,
        body: JSON.stringify({ email: userEmail, password, code }),
      };
      fetchData('auth/password/change/email', request)
        .then(() => {
          NotificationManager.success('Password updated.', 'Sucess!', 5000);
          setTimeout(() => {
            history.push('/login');
          }, 700);
        })
        .catch((response) => {
          try {
            response.then((error) => {
              NotificationManager.error(error.message, 'Error!', 5000);
            });
          } catch (error) {
            NotificationManager.error(error, 'Error!', 5000);
          }
        })
        .finally(() => {
          showLoader(false);
        });
    }
  };

  const handleChangePassword = (e) => {
    const { name, value } = e.target;

    if (name === 'confirmPassword') {
      if (state.password.length >= 3) {
        setState({ ...state, [name]: value, match: state.password === value });
      } else {
        setState({ ...state, [name]: value, match: undefined });
      }
    } else if (state.confirmPassword.length >= 3) {
      setState({ ...state, [name]: value, match: state.confirmPassword === value });
    } else {
      setState({ ...state, [name]: value, match: undefined });
    }
  };

  function TogglePassword() {
    setShowPswd(!showPswd);
  }

  function ToggleNewPassword() {
    setShowConformPswd(!showconfirmPswd);
  }

  return (
    <div className="login">
      <div className="contenedor">
        {
          !emailValid
            ? <FalseEmail email={userEmail} />
            : (
              <div className="card">
                <div className="logo tac">
                  <h3 className="brand">Power of one</h3>
                </div>
                <div className="card-body">
                  <div className="tac">
                    <label className="welcome">Change password</label>
                  </div>

                  <form onSubmit={Submit}>
                    <div className="form-group tal">
                      <label className="label-title">Password</label>
                      <div className="input-group w-100">
                        <input
                          id="password"
                          name="password"
                          type={showPswd ? 'text' : 'password'}
                          className="form-control"
                          value={password}
                          onChange={handleChangePassword}
                          minLength="4"
                          placeholder="Type your new password."
                          required
                        />
                        <div className="input-group-append">
                          <button id="btn-sp" className="btn  btn-show-password" onClick={TogglePassword} type="button">
                            {
                              showPswd
                                ? <span className="far fa-eye-slash" />
                                : <span className="far fa-eye" />
                            }
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group tal">
                      <label className="label-title">Confirm password</label>
                      <div className="input-group w-100">
                        <input
                          type={showconfirmPswd ? 'text' : 'password'}
                          id="confirmPassword"
                          name="confirmPassword"
                          className="form-control"
                          value={confirmPassword}
                          onChange={handleChangePassword}
                          minLength="4"
                          placeholder="Confirm your password."
                          required
                        />
                        <div className="input-group-append">
                          <button id="btn-sp" className="btn  btn-show-password" onClick={ToggleNewPassword} type="button">
                            {
                              showconfirmPswd
                                ? <span className="far fa-eye-slash" />
                                : <span className="far fa-eye" />
                            }
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <ShowPasswordMessage match={match} />
                    </div>
                    <div className="div-submit">
                      <button className="btn btn-primary form-control btn-radius" type="submit">Save</button>
                    </div>

                  </form>
                  <div className="div-submit">
                    <Link to="/login" className="btn btn-info form-control btn-radius">Sign in</Link>
                  </div>
                </div>
              </div>
            )
        }
        <NotificationContainer />
        <Loader />
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  showLoader: PropTypes.func.isRequired,
};

export default loaderConnect(ResetPassword);
