/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes, { object } from 'prop-types';
import moment from 'moment-timezone';
import { Link, useHistory } from 'react-router-dom';
import DateTime from 'react-datetime';
import jwt from 'jwt-decode';
import DraggablePanel from '../../components/Draggable/draggablePanel';
import {
  fetchData, CreateAuthRequest, priority, makeUrlGET, baseUrl,
} from '../../API/constants';
import TaskCard from './taskCard';
import InputDebounce from '../../components/InputDebounce/inputDebounce';
import Typeahead from '../../components/CustomizedTypeahead/typeahead';
import { StatusesContext } from '../../context/status/statusesContext';
import './tasks.scss';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_TASKS':
      return { ...state, tasks: action.payload };
    case 'SET_PANNELS':
      return { ...state, pannels: action.payload };
    case 'SET_FILTER_SEARCH':
      return { ...state, filterSearch: action.payload };
    case 'SET_FILTER_PRIORITY':
      return { ...state, filterPriority: action.payload };
    case 'SET_FILTER_DIFFICULTY':
      return { ...state, filterDifficulty: action.payload };
    case 'SET_FILTER_DATE':
      return { ...state, filterDate: action.payload };
    case 'SET_FILTER_SECTION':
      return { ...state, filterSection: action.payload };
    case 'SET_FILTER_USER':
      return { ...state, filterUser: action.payload };
    case 'RESET_FILTERS':
      return {
        ...state,
        filterSearch: '',
        filterPriority: 0,
        filterDifficulty: 0,
        filterUser: null,
        filterDate: '',
        filterSection: null,
      };
    case 'SET_USER_PERMISSIONS':
      return { ...state, userPermissions: action.payload };
    case 'SET_IS_SUPERVISOR':
      return { ...state, isSupervisor: action.payload };
    default:
      return { ...state };
  }
};

const Kanban = ({ showLoader, NotificationManager }) => {
  const { statuses, updateStatuses } = React.useContext(StatusesContext);
  const initialState = {
    tasks: { count: 0, rows: [], is_supervisor: false },
    pannels: [],
    filterSearch: '',
    filterPriority: null,
    filterUser: null,
    filterDate: '',
    filterDifficulty: null,
    filterSection: null,
    userPermissions: { user_type: 0 },
    isSupervisor: false,
  };

  const history = useHistory();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const {
    tasks,
    pannels,
    filterSearch,
    filterPriority,
    filterDifficulty,
    filterDate,
    filterSection,
    filterUser,
    isSupervisor,
  } = state;

  const getTasks = React.useCallback(async () => {
    showLoader(true);
    const url = `tasks?search=${encodeURIComponent(filterSearch)}&${makeUrlGET({
      priority: filterPriority,
      difficulty: filterDifficulty,
      deadline_date: filterDate && `${moment(filterDate).tz('Atlantic/Bermuda').startOf('day').toISOString()}|${moment(filterDate).tz('Atlantic/Bermuda').endOf('day').toISOString()}`,
      id_section: filterSection,
      users_id: filterUser,
    })}`;
    const request = await CreateAuthRequest('GET', null, true);
    fetchData(url, request)
      .then(response => {
        let helperSupervisor = false;
        const tasksList = response.rows.map(task => {
          if (!helperSupervisor && task.is_supervisor) {
            helperSupervisor = true;
          }
          return { ...task, status: task.task_status.id_status };
        });
        dispatch({ type: 'SET_IS_SUPERVISOR', payload: helperSupervisor });
        dispatch({
          type: 'SET_TASKS',
          payload: {
            ...response,
            rows: tasksList,
          },
        });
      })
      .finally(() => showLoader(false));
  }, [filterSearch, filterPriority, filterDifficulty, filterDate, filterSection, filterUser]);

  const getPanels = React.useCallback(async () => {
    showLoader(true);

    if (statuses.length > 0) {
      const newPannels = [];
      for (let i = 0; i < statuses.length; i += 1) {
        const elm = statuses[i];
        newPannels.push({
          title: elm.name,
          counter: 0,
          color: elm.color,
          id: elm.id_status,
          order: elm.order,
          isRestricted: (elm.id_status === 1 || elm.id_status === 5 || elm.id_status === 6),
          hide: '',
        });
      }
      const orderedPannels = newPannels.sort((a, z) => a.order - z.order);
      dispatch({ type: 'SET_PANNELS', payload: orderedPannels });
    } else {
      const request = await CreateAuthRequest('GET', null, true);
      fetchData('statuses', request)
        .then(response => {
          const newPannels = [];
          for (let i = 0; i < response.rows.length; i += 1) {
            const elm = response.rows[i];
            newPannels.push({
              title: elm.name,
              counter: 0,
              color: elm.color,
              id: elm.id_status,
              order: elm.order,
              isRestricted: (elm.id_status === 1 || elm.id_status === 2 || elm.id_status === 5),
              hide: '',
            });
          }
          const orderedPannels = newPannels.sort((a, z) => a.order - z.order);
          dispatch({ type: 'SET_PANNELS', payload: orderedPannels });
          updateStatuses(response.rows.sort((a, z) => a.order - z.order));
        })
        .finally(() => showLoader(false));
    }
  }, []);

  React.useEffect(() => {
    getPanels();
    // getTask();
  }, []);

  React.useEffect(() => {
    getTasks();
  }, [filterSearch, filterPriority, filterDifficulty, filterDate, filterSection, filterUser]);

  React.useEffect(() => {
    console.log(isSupervisor, pannels.length);
    if (!isSupervisor && pannels.length === 6) {
      if (pannels[5].hide !== 'hide') {
        dispatch({
          type: 'SET_PANNELS',
          payload: pannels.map(p => ((p.order !== 5 && p.order !== 6) ? { ...p, hide: '' } : { ...p, hide: 'hide' })),
        });
      }
    } else if (isSupervisor && pannels[5]?.hide === 'hide') {
      dispatch({
        type: 'SET_PANNELS',
        payload: pannels.map(p => ({ ...p, hide: '' })),
      });
    }
  }, [isSupervisor, pannels]);

  const handleCardClick = (card) => {
    if (card.id_task_parent) {
      history.push(`/pannel/tasks/detail/${card.id_task_parent}/subtask/${card.id_task}`);
    } else {
      history.push(`/pannel/tasks/detail/${card.id_task}`);
    }
  };

  const ChangeTaskStatus = async (task, newStatus) => {
    if ((newStatus === 2 || newStatus === 5 || newStatus === 1) && task.is_supervisor === 0) {
      NotificationManager.warning('You are not task supervisor. You do not have permissions to move this task.', 'Warning!', 6000);
    } else {
      showLoader(true);
      const request = await CreateAuthRequest('PUT', { id_status: newStatus }, true);
      fetchData(`tasks/${task.id_task}/status`, request)
        .then(response => {
          const newTaskList = {
            ...tasks,
            rows: tasks.rows.map(t => {
              if (t.id_task === response.id_task) {
                return {
                  ...t,
                  id_status: response.id_status,
                  task_status: response.task_status,
                  updatedAt: response.updatedAt,
                };
              }
              return { ...t };
            }),
          };
          dispatch({ type: 'SET_TASKS', payload: newTaskList });
          NotificationManager.success('Task has been updated.', 'Success!', 6000);
        })
        .catch((response) => {
          response.then(r => NotificationManager.error(`An error occured.${r.message}`, 'Error!', 6000));
        })
        .finally(() => showLoader(false));
    }
  };

  // region filters

  const handleSearchByName = (text) => {
    dispatch({ type: 'SET_FILTER_SEARCH', payload: text });
  };

  const handleChangePriorityFilter = ({ target }) => {
    if (target.value === '0') {
      dispatch({ type: 'SET_FILTER_PRIORITY', payload: null });
    } else {
      dispatch({ type: 'SET_FILTER_PRIORITY', payload: target.value });
    }
  };

  const handleChangeDifficultyFilter = ({ target }) => {
    if (target.value === '0') {
      dispatch({ type: 'SET_FILTER_DIFFICULTY', payload: null });
    } else {
      dispatch({ type: 'SET_FILTER_DIFFICULTY', payload: target.value });
    }
  };

  const handleChangeDateFilter = (value) => {
    dispatch({ type: 'SET_FILTER_DATE', payload: value });
  };

  const handleChangeSectionFilter = (value) => {
    dispatch({ type: 'SET_FILTER_SECTION', payload: value });
  };

  const handleChangeUserFilter = (value) => {
    dispatch({ type: 'SET_FILTER_USER', payload: value });
  };

  const handleResetFilters = () => {
    dispatch({ type: 'RESET_FILTERS' });
    const closebtn = document.getElementsByClassName('close rbt-close');
    if (closebtn.length > 0) {
      if (closebtn.length > 1) {
        closebtn[1].click();
      }
      closebtn[0].click();
    }
  };
  // endregion

  return (
    <div className="tal task-list">

      <div className="row mg-top" style={{ paddingLeft: '.5em' }}>
        <div className="col-12 col-md-6" style={{ paddingRight: '12px' }}>
          <InputDebounce
            placeholder="Search task by name"
            handleInputChange={handleSearchByName}
            value={filterSearch}
          />
        </div>
        <div className="col-12 col-md-3">
          <select className="form-control input-shadow" value={filterPriority} onChange={handleChangePriorityFilter}>
            <option value={0}>All Priorities</option>
            {
              priority.map(p => <option key={p.id} value={p.id}>{p.name}</option>)
            }
          </select>
        </div>
        <div className="col-12 col-md-3">
          <select className="form-control input-shadow" value={filterDifficulty} onChange={handleChangeDifficultyFilter}>
            <option value={0}>All difficulties</option>
            {
              priority.map(p => <option key={p.id} value={p.id}>{p.name}</option>)
            }
          </select>
        </div>
      </div>
      <div className="row mg-top" style={{ paddingLeft: '.5em' }}>
        <div className="col-12 col-md-3">
          <DateTime
            className="input-shadow"
            closeOnSelect
            dateFormat="DD/MMM/YYYY"
            timeFormat={false}
            onChange={(dateMoment) => handleChangeDateFilter(moment(dateMoment))}
            value={filterDate && moment(filterDate).format('DD/MMM/YYYY')}
            inputProps={{ placeholder: 'Filter by due date', readOnly: true, style: { backgroundColor: '#fff' } }}
          />
        </div>
        {
          isSupervisor
          && (
            <>
              <div className="col-12 col-md-4">
                <Typeahead
                  labelKey="name"
                  url={`${process.env.REACT_APP_API_URL}sections`}
                  multiple={false}
                  minLength={3}
                  onChange={(option) => handleChangeSectionFilter(option[0]?.id_section)}
                  placeholder="Filter by section..."
                  itemChildren={(option) => (
                    <span>{`${option.id_section} ${option.name}`}</span>
                  )}
                  inputProps={{ required: 'required' }}
                />
              </div>
              <div className="col-12 col-md-4">
                <Typeahead
                  labelKey={(option) => `${option.name} ${option.last_name}`}
                  url={(filterSection && typeof filterSection !== 'undefined') ? `${baseUrl}sections/${filterSection}/users` : `${baseUrl}users`}
                  // filterBy="type=2"
                  multiple={false}
                  minLength={3}
                  onChange={(option) => handleChangeUserFilter(option[0]?.id_user)}
                  placeholder="Filter by user..."
                  itemChildren={
                    option => (
                      <div>
                        <div>{`${option.name} ${option.last_name}`}</div>
                        <span className="badge badge-secondary">{`${option.email}`}</span>
                      </div>
                    )
                  }
                // inputProps={{ disabled: !filterSection }}
                />
              </div>
            </>
          )
        }

        <div className="col-12 col-md-1 tar" style={{ paddingLeft: '0', fontSize: '12px' }}>
          <button type="button" className="btn btn-primary btn-radius" onClick={handleResetFilters}>Reset</button>
        </div>
      </div>
      <DraggablePanel
        view="kanban"
        dropDrag={ChangeTaskStatus}
        // initialData={mapedCards}
        pannels={pannels}
        cards={tasks.rows}
        clickOnCard={handleCardClick}
        idKey="id_task"
        statusLabel="id_status"
        component={TaskCard}
      />
      {
        tasks.is_supervisor
        && <Link title="New task" to="/pannel/tasks/new" className="btn btn-primary float-add"><i className="fas fa-plus" /></Link>
      }
    </div>
  );
};

Kanban.propTypes = {
  showLoader: PropTypes.func,
  NotificationManager: object,
};

Kanban.defaultProps = {
  showLoader: f => f,
  NotificationManager: null,
};

export default Kanban;
