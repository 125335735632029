/* eslint-disable arrow-parens */
import Node from './node';

class MyTree {
  constructor() {
    this.root = null;
    this.add = this.add.bind(this);
    this.preOrder = this.preOrder.bind(this);
    this.postOrder = this.postOrder.bind(this);
    this.convertToList = this.convertToList.bind(this);
    this.checkForNotFound = this.checkForNotFound.bind(this);
    this.notFoundParent = [];
  }

  clearNotFound() {
    this.notFoundParent = [];
  }

  checkForNotFound() {
    let count = 0;
    while (this.notFoundParent.length > 0 && count < 5) {
      count += 1;
      for (let i = 0; i < this.notFoundParent.length; i += 1) {
        const node = this.notFoundParent[i];
        this.notFoundParent.splice(i, 1);
        this.add(node.value, node.info, node.info.id_parent_item);
      }
    }
    this.clearNotFound();
  }

  find(node, idParent) {
    if (node.value === idParent) {
      return node;
    }
    if (node.children.length > 0) {
      let result = null;
      for (let i = 0; result == null && i < node.children.length; i += 1) {
        result = this.find(node.children[i], idParent);
      }
      return result;
    }
    return null;
  }

  add(idNode, info, idParent) {
    const parent = idParent !== null ? this.find(this.root, idParent) : null;
    const node = new Node(idNode, info);
    if (parent !== null && typeof parent !== 'undefined') {
      node.info.padding = `${parseFloat(parent.info.padding) + 1}`;
      parent.children.push(node);
    } else if (!this.root) {
      node.info.padding = '-1';
      this.root = node;
    } else {
      this.notFoundParent.push(node);
    }
  }

  preOrder(node, fn) {
    if (!node) {
      return;
    }
    if (fn) {
      fn(node);
    }
    for (let i = 0; i < node.children.length; i += 1) {
      this.pre_order(node.children[i], fn);
    }
  }

  postOrder(node, fn) {
    if (!node) {
      return;
    }
    for (let i = 0; i < node.children.length; i += 1) {
      this.postOrder(node.children[i], fn);
    }
    if (fn) {
      fn(node);
    }
  }

  convertToList(node) {
    const listElement = {
      ...node.info,
      list_items: [],
    };
    if (node.children.length > 0) {
      for (let i = 0; i < node.children.length; i += 1) {
        listElement.list_items = node.children.map(n => this.convertToList(n));
      }
    }
    return listElement;
  }
}

const CreateTree = (list) => {
  const myTree = new MyTree();
  myTree.add(0, { id_list_item: 0, name: 'root' }, null);
  for (let i = 0; i < list.length; i += 1) {
    const element = list[i];
    if (!element.disabled) {
      if (element.id_parent_item) {
        myTree.add(element.id_list_item, element, element.id_parent_item);
      } else {
        myTree.add(element.id_list_item, element, 0);
      }
    }
  }
  myTree.checkForNotFound();
  const newList = myTree.convertToList(myTree.root);
  return newList;
};

CreateTree.defaultProps = {
  list: [],
};

export default CreateTree;
