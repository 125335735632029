/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { ChatContext } from './chatContext/chatContext';
import { ImageUser } from '../../components/ShowUsers/usersList';
import { CreateAuthRequest, fetchData } from '../../API/constants';

const acceptedFiles = ['pdf', 'jpg', 'doc', 'docx', 'xls', 'xlsx', 'png', 'jpeg', 'svg', 'gif'];
const maxSize = 20000000;

const ShowSelectedUser = ({ addNewMessage, NotificationManager }) => {
  const { chat } = React.useContext(ChatContext);
  const { selectedChat } = chat;

  const handleInputChange = async (e) => {
    const { files } = e.target;
    const newFile = files[0];

    if (newFile) {
      if (newFile.size <= maxSize) {
        if (acceptedFiles.includes(newFile.name.split('.').pop().toLowerCase())) {
          // setFile(newFile);
          const request = await CreateAuthRequest('POST', { id_user: selectedChat.id_user, message: '' }, true);
          fetchData('chat/users/messages', request)
            .then(async (response) => {
              const formData = new FormData();
              formData.append('file', newFile);
              const requestFile = await CreateAuthRequest('POST', formData, false);
              // requestFile.headers.append('Content-Type', 'multipart/form-data');
              fetchData(`chat/users/messages/${response.id_user_message}/files`, requestFile)
                .then((responseFile) => {
                  const message = { ...response, message_files: [responseFile] };
                  addNewMessage(message);
                  document.getElementById('inputfile').value = '';
                });
            });
        } else {
          NotificationManager.warning('This type of file is not allowed.', 'Warning!', 5000);
        }
      } else {
        NotificationManager.warning('Max size 20MB.', 'Warning!', 5000);
      }
    }
  };
  console.log('selectedChat', selectedChat);
  return (
    <div className="list-group-item user-section shadow">
      {
        selectedChat
        && (
          <div className="row">
            <div className="col">
              <ImageUser userName={`${selectedChat.name} ${selectedChat.last_name}`} urlImage={selectedChat.image_url} size={37} />
              {`${selectedChat.name} ${selectedChat.last_name}`}
            </div>
            <div className="col-1 tar">
              <label className="btn btn-light">
                {/* <button type="button" className="btn btn-light"> */}
                <i className="fas fa-paperclip" />
                {/* </button> */}
                <input
                  id="inputfile"
                  type="file"
                  className="hide"
                  accept="pdf, jpg, doc, docx, xls, png, jpeg, svg, gif"
                  onChange={handleInputChange}
                />
              </label>
            </div>
          </div>
        )
      }
    </div>
  );
};

ShowSelectedUser.propTypes = {
  addNewMessage: PropTypes.func,
  NotificationManager: PropTypes.object,
};

ShowSelectedUser.defaultProps = {
  addNewMessage: (f) => f,
  NotificationManager: null,
};

export default ShowSelectedUser;
