/* eslint-disable import/no-cycle */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable arrow-parens */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import Dropdown from '../dropdown/dropdown';
import { priority as priorityList, fetchData, CreateAuthRequest } from '../../API/constants';
import useClickOutside from '../../hooks/useClickOutside';
import InputDebounce from '../InputDebounce/inputDebounce';
import Typeahead from '../CustomizedTypeahead/typeahead';
import UsersList from '../ShowUsers/usersList';
import ListWithParent from './listLevelN';

const TodoElement = ({
  idTask, elm,
  parent,
  handleEditItem,
  handleIndent, handleOutdent,
  draggable, setDraggable, dropDrag,
  dragingOverElm,
  setDraggingOverElm,
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [item, setItem] = React.useState(elm);
  const [isSearching, setIsSearching] = React.useState();

  const handleIsEditing = () => {
    setIsEditing(!isEditing);
  };

  const handleNameChange = (text) => {
    if (text !== elm.name) {
      handleEditItem(item.id_list_item, { name: text });
      setItem({ ...elm, name: text });
    }
  };

  const ReaderRef = useClickOutside(isEditing, () => {
    handleIsEditing();
  });

  const onIndentClick = () => {
    handleIndent(item, parent);
  };

  const handleSearching = () => {
    setIsSearching(!isSearching);
  };

  const handleAddUser = async (newUserId) => {
    // const usersIds = item.users.map(u => u.id_user);
    // usersIds.push(newUserId);
    const request = await CreateAuthRequest('POST', { users: [newUserId] }, true);
    fetchData(`tasks/lists/items/${item.id_list_item}/users`, request)
      .then(response => {
        setItem({ ...item, users: [...item.users, ...response.rows] });
      });
    handleSearching();
  };

  const changePriority = (priority) => {
    handleEditItem(item.id_list_item, { priority: priority.id });
    setItem({ ...elm, priority: priority.id });
  };

  const handleDelete = () => {
    handleEditItem(item.id_list_item, { disabled: true });
  };

  const onOutdentClick = () => { handleOutdent(item, parent); };

  function drag() {
    if (typeof draggable === 'undefined' || draggable === null) {
      setDraggable(item);
    } else if (draggable.id_list_item !== item.id_list_item) {
      setDraggable(item);
    }
  }

  function dragOver(e) {
    e.preventDefault();
    e.stopPropagation();
    if (draggable.id_list_item !== item.id_list_item) {
      if (typeof dragingOverElm === 'undefined' || dragingOverElm === null) {
        setDraggingOverElm(item);
      } else if (dragingOverElm.id_list_item !== item.id_list_item) {
        setDraggingOverElm(item);
      }
    }
  }

  function handleDone() {
    handleEditItem(item.id_list_item, { done: !item.done });
    setItem({ ...item, done: !item.done });
  }

  return (
    <>
      {isEditing
        ? (
          <div ref={ReaderRef} style={{ marginTop: '.7em', marginLeft: `${elm.padding}em` }} key={item.id_list_item}>
            <InputDebounce
              handleInputChange={handleNameChange}
              icon=""
              placeholder="type task"
              value={item.name}
              keyInput={item.id_list_item}
              onEnter={handleIsEditing}
            />
            <div className="row shadow" style={{ margin: '0' }}>
              <div className="col-6" style={{ padding: '0' }}>
                <div className="btn-group" role="group" aria-label="Basic example">
                  <button type="button" className="btn btn-outline-danger" onClick={handleDelete}>
                    <i className="fas fa-trash-alt" />
                  </button>
                  <Dropdown
                    options={priorityList}
                    onSelectOption={changePriority}
                    defaultSelected={item.priority}
                  />
                  <button type="button" className="btn btn-outline-secondary" onClick={onIndentClick}>
                    <i className="fas fa-indent" />
                  </button>
                  <button type="button" className="btn btn-outline-secondary" onClick={onOutdentClick}>
                    <i className="fas fa-outdent" />
                  </button>
                  <button type="button" className="btn btn-outline-secondary" onClick={handleSearching}>
                    <i className="fas fa-user-plus" />
                    {' Users'}
                  </button>
                </div>
              </div>
              <div className="col " style={{ padding: '.5em' }}>
                <UsersList
                  keylabel={null}
                  users={item.users}
                  zeroResutls=""
                  useDiv={false}
                  size={25}
                  maxUsersToShow={8}
                />
              </div>
              {
                isSearching
                && (
                  <div className="col-12" style={{ padding: '0 .5em' }}>
                    <Typeahead
                      labelKey={(option) => `${option.name} ${option.last_name}`}
                      url={`${process.env.REACT_APP_API_URL}tasks/${idTask}/users`}
                      multiple={false}
                      minLength={0}
                      onChange={(option) => handleAddUser(option[0]?.id_user)}
                      placeholder="Type user to search..."
                      itemChildren={
                        option => (
                          <div>
                            <div>{`${option.name} ${option.last_name}`}</div>
                            <span className="badge badge-secondary">{`${option.email}`}</span>
                          </div>
                        )
                      }
                    />
                  </div>
                )
              }
            </div>
          </div>
        )
        : (
          <div
            className="subtask"
            style={{
              marginLeft: `${elm.padding}em`,
              border: dragingOverElm && dragingOverElm.id_list_item === item.id_list_item ? '1px dashed red' : '',
            }}
            onDrag={drag}
            onDragOver={dragOver}
            draggable
          >
            <div className="row">
              <div
                className="col"
                style={{
                  paddingLeft: '.5em',
                  paddingRight: '0',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <i
                  className="fas fa-grip-vertical"
                  style={{
                    color: '#888888',
                    cursor: 'grab',
                    marginRight: '.5em',
                  }}
                />
                <button onClick={handleDone} className={`btn-checkbox ${item.done && 'done'}`} type="button">{item.done && <i className="fas fa-check" />}</button>
              </div>
              <div className="col-5" style={{ paddingLeft: '0', paddingRight: '0' }} onClick={handleIsEditing}>
                {item.name}
              </div>
              <div className="col-3" onClick={handleIsEditing}>
                <UsersList
                  keylabel={null}
                  users={item.users}
                  zeroResutls=""
                  useDiv={false}
                  size={25}
                  maxUsersToShow={2}
                />
              </div>
              <div className="col-3" onClick={handleIsEditing}>
                <i className="fas fa-flag" style={{ color: `${priorityList.find(p => p.id === item.priority).color}` }} />
                {' '}
                {moment(item.updatedAt).tz('Atlantic/Bermuda').format('MMM DD')}
              </div>
            </div>
          </div>
        )}
      {
        elm.list_items
        && (elm.list_items.length > 0
          && (draggable ? draggable.id_list_item !== item.id_list_item : true)
          && (
            <ListWithParent
              elmList={{ ...elm, id_task: idTask }}
              parent={elm}
              draggable={draggable}
              setDraggable={setDraggable}
              dropDrag={dropDrag}
              dragingOverElm={dragingOverElm}
              setDraggingOverElm={setDraggingOverElm}
              handleEditItem={handleEditItem}
              handleIndent={handleIndent}
              handleOutdent={handleOutdent}
            />
          ))
      }
    </>
  );
};

TodoElement.propTypes = {
  idTask: PropTypes.number,
  elm: PropTypes.object.isRequired,
  handleEditItem: PropTypes.func,
  handleIndent: PropTypes.func,
  handleOutdent: PropTypes.func,
  draggable: PropTypes.object,
  setDraggable: PropTypes.func,
  dropDrag: PropTypes.func,
  dragingOverElm: PropTypes.object,
  setDraggingOverElm: PropTypes.func,
  parent: PropTypes.object,
};

TodoElement.defaultProps = {
  idTask: null,
  handleEditItem: f => f,
  handleIndent: f => f,
  handleOutdent: f => f,
  draggable: null,
  setDraggable: f => f,
  dropDrag: f => f,
  dragingOverElm: null,
  setDraggingOverElm: f => f,
  parent: null,
};

export default TodoElement;
