/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import Header from '../../components/header/header';
import { CreateAuthRequest, fetchData } from '../../API/constants';

const NewSection = ({ NotificationManager }) => {
  const history = useHistory();
  const [form, setForm] = React.useState({});
  const { idSection } = useParams();
  const isEditing = !!idSection;

  const getSectionInfo = React.useCallback(async () => {
    if (idSection) {
      const request = await CreateAuthRequest('GET', null, true);
      fetchData(`sections/${idSection}`, request)
        .then((response) => {
          setForm({
            name: response.name,
            contact_person: response.contact_person,
            phone_number: response.phone_number,
          });
        });
    }
  }, [idSection]);
  React.useEffect(() => { getSectionInfo(); }, [getSectionInfo]);

  const handleFormChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (e.target.checkValidity()) {
      if (isEditing) {
        const request = await CreateAuthRequest('PUT', form, true);
        fetchData(`sections/${idSection}`, request)
          .then((response) => {
            NotificationManager.success('Sectionhas bean updated.', 'Success!', 5000);
            setForm({
              name: response.name,
              contact_person: response.contact_person,
              phone_number: response.phone_number,
            });
          });
      } else {
        const request = await CreateAuthRequest('POST', form, true);
        fetchData('sections', request)
          .then((response) => {
            history.replace(`/pannel/sections/edit/${response.id_section}`);
            NotificationManager.success('Sectionhas bean created.', 'Success!', 5000);
          })
          .catch((error) => {
            try {
              error.then((response) => {
                NotificationManager.error(response.message, 'Error!', 5000);
              });
            } catch (error1) {
              NotificationManager.error(error1, 'Error!', 5000);
            }
          });
      }
    }
  };

  return (
    <div className="nkdx">
      <Header
        title={isEditing ? 'Edit Section' : 'Create Section'}
      />
      <div className="card">
        <div className="card-body">
          <form className="form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label>Name</label>
                  <input className="form-control" placeholder="Type sectionname" name="name" value={form.name} onChange={handleFormChange} required />
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label>Contact person (optional)</label>
                  <input className="form-control" placeholder="Type contact person name" name="contact_person" value={form.contact_person} onChange={handleFormChange} />
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label>Phone number (optional)</label>
                  <input className="form-control" placeholder="type phone number" name="phone_number" value={form.phone_number} onChange={handleFormChange} />
                </div>
              </div>
            </div>
            <div className="mg-top tac">
              <button type="submit" className="btn btn-primary btn-radius">{isEditing ? 'Save' : 'Create'}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

NewSection.propTypes = {
  NotificationManager: PropTypes.object,
};

NewSection.defaultProps = {
  NotificationManager: null,
};

export default NewSection;
