/* eslint-disable no-else-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes, { object } from 'prop-types';
import moment from 'moment-timezone';
import DateTime from 'react-datetime';
// import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Header from '../../components/header/header';
import { CreateAuthRequest, fetchData } from '../../API/constants';
import TypeaheadWithOptions from '../../components/Typeahead/typeahead';
import Typeahead from '../../components/CustomizedTypeahead/typeahead';
import './notifications.scss';
import { UserContext } from '../../context/userContext/userContext';

const NewNotification = ({ showLoader, NotificationManager }) => {
  // const { idNotification } = useParams();
  // TODO: length validations title and description
  const history = useHistory();
  const { user } = React.useContext(UserContext);
  const [form, setForm] = React.useState({
    title: '',
    message: '',
    sent_date: null,
  });
  const [type, setType] = React.useState('');
  const [validated, setValidated] = React.useState(false);

  const PostNotification = async () => {
    showLoader(true);
    const obj = { title: form.title, message: form.message };
    let url = '';
    if (type === 'Sections') {
      url = 'notifications/section';
      obj.id_section = form.id_section.map((section) => section.id_section);
    } else {
      url = 'notifications/task';
      obj.id_task = form.id_task;
    }

    if (form.sent_date !== null) {
      obj.sent_date = form.sent_date;
    }
    // console.log(url, obj);
    const request = await CreateAuthRequest('POST', obj, true);
    fetchData(url, request)
      .then((response) => {
        NotificationManager.success('Notification has been created.', 'Success!', 5000);
        history.replace(`/pannel/notifications/details/${response.id_notification}`);
      })
      .catch((errorResponse) => {
        errorResponse.then((er) => {
          NotificationManager.error(er.message, 'Error!', 5000);
        });
      })
      .finally(() => { showLoader(false); });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setValidated(true);
    if (e.target.checkValidity()) {
      PostNotification();
    } else {
      NotificationManager.warning('Complete all required fields.', 'Warning!', 5000);
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name === 'sent_date') {
      const day = moment().tz('Atlantic/Bermuda');
      const date = moment(value);
      const isValid = date.isAfter(day);
      setForm({ ...form, [name]: isValid ? value : day });
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const valid = (current) => {
    const day = moment().tz('Atlantic/Bermuda').add(-1, 'days');
    return current.isAfter(day);
  };

  const handleChangeType = ({ target }) => {
    setType(target.value);
  };

  const getTypeFilter = () => {
    if (type === '') {
      return null;
    }
    if (type === 'Sections') {
      return (
        <div className="col-12 col-md-4">
          <label>Search section</label>
          <Typeahead
            labelKey="name"
            id="sections"
            url={`${process.env.REACT_APP_API_URL}${user.type === 1 ? 'sections' : 'me/sections?user_type=2'}`}
            multiple
            minLength={3}
            onChange={(options) => handleFormChange({ target: { name: 'id_section', value: options } })}
            placeholder="Search sections..."
            itemChildren={(option) => (
              <span>{`${option.id_section} ${option.name}`}</span>
            )}
          // inputProps={{ required: 'required' }}
          />
        </div>
      );
    } else {
      return (
        <div className="col-12 col-md-4">
          <label>Search task</label>
          <TypeaheadWithOptions
            labelKey="title"
            idLabel="id_task"
            url="tasks"
            minLength={3}
            onSelect={(option) => handleFormChange({ target: { name: 'id_task', value: option?.id_task } })}
            placeholder="Search task..."
            showResultsOnEmpty={false}
            renderOptions={(option) => (
              <span>{option.title}</span>
            )}
            inputProps={{ required: 'required' }}
          />
        </div>
      );
    }
  };

  return (
    <div className="new-notification">
      <Header
        title="Create Notification"
      />
      <div className="card">
        <div className="card-body">
          <form className={`form needs-validation ${validated ? ' was-validated' : ''}`} onSubmit={handleSubmit} noValidate>
            <div className="form-group">
              <label>Title</label>
              <input name="title" className="form-control input-shadow" value={form.title} onChange={handleFormChange} required />
            </div>
            <div className="form-group">
              <label>Message</label>
              <textarea rows="6" style={{ resize: 'none' }} name="message" className="form-control input-shadow" value={form.message} onChange={handleFormChange} required />
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label>Type</label>
                  <select className="form-control input-shadow" value={type} onChange={handleChangeType} required>
                    <option value="">Choose type</option>
                    <option value="Sections">Section</option>
                    <option value="Task">Task</option>
                  </select>
                </div>
              </div>
              {
                getTypeFilter()
              }
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label>Date</label>
                  <DateTime
                    className="input-shadow"
                    closeOnSelect
                    dateFormat="DD/MMM/YYYY"
                    timeFormat
                    isValidDate={valid}
                    onChange={(dateMoment) => handleFormChange({ target: { name: 'sent_date', value: moment(dateMoment) } })}
                    value={form.sent_date && moment(form.sent_date).format('DD/MMM/YYYY h:mm a')}
                    inputProps={{ placeholder: 'Choose date to send notification.' }}
                    displayTimeZone="Atlantic/Bermuda"
                  />
                </div>
              </div>
            </div>
            <div className="tac mg-top">
              <button type="submit" className="btn btn-primary btn-radius">Create notification</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

NewNotification.propTypes = {
  showLoader: PropTypes.func,
  NotificationManager: PropTypes.object,
};

NewNotification.defaultProps = {
  showLoader: (f) => f,
  NotificationManager: null,
};

export default NewNotification;
