import React from 'react';

export const sidebar = 'toggled';

export const updateSidebar = () => { };

export const SidebarContext = React.createContext({
  sidebar,
  updateSidebar: () => { },
});
