/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useHistory, Link, useLocation } from 'react-router-dom';
import loaderConnect from '../../components/Loader/loaderConnect';
import Loader from '../../components/Loader/loader';
// import logo from '../../assets/logo192.png';
import './login.scss';

const Login = (props) => {
  const { showLoader } = props;
  const history = useHistory();
  const [formValues, changeValue] = React.useState({ email: '', password: '' });
  const [active, changeActive] = React.useState(false);
  const [newPswdActive, changeNewPswdActive] = React.useState(false);
  const location = useLocation();

  console.log('location', location);

  function ChangeValues(e) {
    const { name, value } = e.target;
    changeValue({ ...formValues, [name]: value });
  }

  function TogglePassword() {
    changeActive(!active);
  }

  function ToggleNewPassword() {
    changeNewPswdActive(!newPswdActive);
  }

  const Submit = async (e) => {
    e.preventDefault();
    if (e.target.checkValidity()) {
      showLoader(true);

      const request = {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify(formValues),
      };

      await fetch(`${process.env.REACT_APP_API_URL}auth/login`, request)
        .then(response => response.json())
        .then(response => {
          if (response.message) {
            NotificationManager.error('Wrong user/password.', 'Validation error', 5000);
          } else {
            sessionStorage.setItem('token', response.token);
            NotificationManager.success('', '¡Welcome!', 700);
            setTimeout(() => {
              history.push('/pannel');
            }, 700);
          }
        })
        .catch((error) => {
          console.error(error);
          NotificationManager.error('Try it later.', 'Error', 4000);
        })
        .finally(() => { showLoader(false); });
    } else {
      NotificationManager.error('Some fileds are required.', 'Valitadion Error', 4000);
    }
  };

  return (
    (
      <div className="login">
        <div className="contenedor">
          <div className="card">
            <div className="logo tac">
              <h3 className="brand">Power of one</h3>
              {/* <img style={{ width: 'auto', height: '90%', marginTop: '5px' }} src={logo} alt="Áreas comunes" /> */}
            </div>
            <div className="card-body">
              {/* <h3 className="my-3"> Login:</h3> */}
              <div className="tac">
                <label className="welcome">Power of one</label>
              </div>
              <form onSubmit={Submit}>
                <div className="form-group tal">
                  <label className="label-title">User</label>
                  <input
                    name="email"
                    id="email"
                    value={formValues.email}
                    type="email"
                    onChange={ChangeValues}
                    className="form-control"
                    required
                    placeholder="Type your email"
                  />
                </div>
                <div className="form-group tal">
                  <label className="label-title">Password</label>
                  <div className="input-group w-100">
                    <input
                      className="form-control"
                      name="password"
                      id="password"
                      value={formValues.password}
                      type={active ? 'text' : 'password'}
                      onChange={ChangeValues}
                      minLength="6"
                      required
                      placeholder="Type your password"
                    />
                    <div className="input-group-append">
                      <button id="btn-sp" className="btn  btn-show-password" onClick={TogglePassword} type="button">
                        {
                          active
                            ? <span className="far fa-eye-slash" />
                            : <span className="far fa-eye" />
                        }
                      </button>
                    </div>
                  </div>
                </div>
                {
                  location.search === '?firsttime=true'
                  && (
                    <div className="form-group tal">
                      <label className="label-title">New Password</label>
                      <div className="input-group w-100">
                        <input
                          className="form-control"
                          name="newPassword"
                          id="newPassword"
                          value={formValues.newPassword}
                          type={newPswdActive ? 'text' : 'password'}
                          onChange={ChangeValues}
                          minLength="6"
                          required
                          placeholder="Type your password"
                        />
                        <div className="input-group-append">
                          <button id="btn-sp" className="btn  btn-show-password" onClick={ToggleNewPassword} type="button">
                            {
                              newPswdActive
                                ? <span className="far fa-eye-slash" />
                                : <span className="far fa-eye" />
                            }
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                }

                <div className="div-submit">
                  <button type="submit" className="btn btn-primary form-control btn-radius">Sign in</button>
                </div>
              </form>
              <div className="div-submit">
                <Link to="/recovery_password" className="btn btn-info form-control btn-radius">Change password</Link>
              </div>
            </div>
          </div>
        </div>
        <NotificationContainer />
        <Loader />
      </div>
    )
  );
};

Login.propTypes = {
  showLoader: PropTypes.func.isRequired,
};

export default loaderConnect(Login);
