/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import {
  fetchData, CreateAuthRequest, makeUrlGET, priority as priorityList,
} from '../../API/constants';
import UsersList from '../../components/ShowUsers/usersList';

const SubtasksList = ({ idTask }) => {
  const history = useHistory();
  const [subtasksList, setSubtaskList] = React.useState({ count: 0, rows: [] });
  const GetSubtask = React.useCallback(async () => {
    const request = await CreateAuthRequest('GET', null, true);
    fetchData(`tasks?${makeUrlGET({ id_task_parent: idTask })}`, request)
      .then(response => {
        setSubtaskList(response);
      });
  }, [idTask]);
  React.useEffect(() => {
    GetSubtask();
  }, [idTask]);

  const goToSubtask = (idSub) => {
    history.push(`/pannel/tasks/detail/${idTask}/subtask/${idSub}`);
  };


  return (
    <div className="subtasks">
      <label>Subtasks</label>
      <div>
        <Link to={`/pannel/tasks/${idTask}/subtask`}>
          <i className="fas fa-plus" />
          {' Add Subtask'}
        </Link>
      </div>
      <div>
        {
          subtasksList.count > 0
            ? subtasksList.rows.map(subtask => (
              <div
                key={subtask.id_task}
                className="subtask input-shadow"
                onClick={() => goToSubtask(subtask.id_task)}
              >
                <div className="row">
                  <div className="col-5">
                    {subtask.title}
                  </div>
                  <div className="col-3">
                    <UsersList
                      keylabel={null}
                      users={subtask.users}
                      zeroResutls=""
                      useDiv={false}
                      size={25}
                      maxUsersToShow={2}
                    />
                  </div>
                  <div className="col-2" title={priorityList.find(p => p.id === subtask.priority).name}>
                    <i className="fas fa-flag" style={{ color: `${priorityList.find(p => p.id === subtask.priority).color}` }} />
                    {' '}
                    {subtask.deadline_date
                      && moment(subtask.deadline_date).tz('Atlantic/Bermuda').format('MMM DD')}
                  </div>
                  <div className="col tac">
                    <div
                      style={{ backgroundColor: `#${subtask.task_status.status.color}`, color: '#fff', borderRadius: '20px' }}
                    >
                      {subtask.task_status.status.name}
                    </div>
                  </div>
                </div>
              </div>
            ))
            : (<div className="no-subtask">There is no subtasks</div>)
        }
      </div>
    </div >
  );
};

export default SubtasksList;
