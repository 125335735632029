/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import jwtDecode from 'jwt-decode';
import { MentionsInput, Mention } from 'react-mentions';
import { fetchData, CreateAuthRequest, makeUrlGET } from '../../API/constants';
import { ImageUser } from '../../components/ShowUsers/usersList';
import { propTypes } from 'react-bootstrap/esm/Image';

// region ADDCOMMENT
const AddComment = ({
  onSubmit, idTask,
}) => {
  const [message, setMessage] = React.useState('');
  const [results, setResults] = React.useState([{ id: 1, display: 'mario' }, { id: 2, display: 'marco' }, { id: 3, display: 'mariano' }]);

  // React.useEffect(() => {
  //   connsole.log(message);
  // }, [message]);

  const formatMessage = (msg) => msg.replace(/\[.*?\]/gi, '');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formatMessage(message));
    setMessage('');
  };

  const handleInputChange = ({ target }) => {
    setMessage(target.value);
  };

  const fetchUsers = async (query, callback) => {
    if (!query) return;
    const request = await CreateAuthRequest('GET', null, true);
    fetchData(`tasks/${idTask}/users?search=${query}`, request)
      .then(res => res.rows.map(usr => ({ id: usr.id_user, display: `${usr.name} ${usr.last_name}` })))
      .then(callback);
  };

  return (
    <form onSubmit={handleSubmit}>
      <MentionsInput
        singleLine
        value={message}
        onChange={handleInputChange}
        placeholder="Type a comment"
        className="xd"
        allowSuggestionsAboveCursor
        style={{
          control: {
            backgroundColor: '#fff',
            fontSize: 14,
            fontWeight: 'normal',
          },

          '&multiLine': {
            control: {
              // fontFamily: 'monospace',
              minHeight: 63,
            },
            highlighter: {
              // padding: 9,
              padding: '.7em .5em',
              border: '1px solid transparent',
            },
            input: {
              // padding: 9,
              padding: '.7em .5em',
            },
          },

          suggestions: {
            list: {
              backgroundColor: 'white',
              border: '1px solid rgba(0,0,0,0.15)',
              fontSize: 14,
            },
            item: {
              padding: '5px 15px',
              borderBottom: '1px solid rgba(0,0,0,0.15)',
              '&focused': {
                backgroundColor: '#cee4e5',
              },
            },
          },
        }}
      >
        <Mention
          trigger="@"
          data={fetchUsers}
          markup="@__id__[__display__]"
          displayTransform={(id, display) => `@${display}`}
          className="mention"
          style={{ backgroundColor: '#30a4a8' }}
          renderSuggestion={(
            suggestion,
            search,
            highlightedDisplay,
            index,
            focused,
          ) => (
              <div className={`user ${focused ? 'focused' : ''}`}>
                {highlightedDisplay}
              </div>
            )}
        />
      </MentionsInput>
      {/* <input
        name="message"
        className="form-control"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type message"
        required
      /> */}
    </form>
  );
};

AddComment.propTypes = {
  onSubmit: PropTypes.func,
  idTask: PropTypes.string,
};
AddComment.defaultProps = {
  onSubmit: f => f,
  idTask: null,
};

// endregion

// region commentLOG

const CommentLog = ({ comment, user }) => {
  const formatMessage = () => {
    let newMsg = comment.message;
    const users = comment.message.match(/@[0-9]+/g);
    if (users) {
      newMsg = '';
      for (let i = 0; i < users.length; i += 1) {
        const element = parseInt(users[i].replace(/@/g, ''), 10);
        const usr = comment.mention_users.find(u => u.id_user === element);
        if (usr) {
          const username = (`@${usr.name} ${usr.last_name}`);
          newMsg += comment.message.replace(users[i], `<span class="tag-user">${username}</span>`);
        }
      }
    }
    return { __html: newMsg };
  };
  return (
    <div className="log-registry">
      {
        user.id_user === comment.user.id_user
          ? (
            <>
              <div className="log-comment">
                <div className="">
                  <div className=" date">
                    {moment(comment.createdAt).tz('Atlantic/Bermuda').format('MMM DD, YYYY hh:mm A')}
                  </div>
                  <div className=" name">
                    {`${comment.user.name} ${comment.user.last_name}`}
                  </div>
                </div>
                <p dangerouslySetInnerHTML={formatMessage()} />
              </div>
              <ImageUser
                // urlImage: PropTypes.string,
                userName={`${comment.user.name} ${comment.user.last_name}`}
                size={40}
              />
            </>
          )
          : (
            <>
              <ImageUser
                // urlImage: PropTypes.string,
                userName={`${comment.user.name} ${comment.user.last_name}`}
                size={40}
              />
              <div className="log-comment">
                <div className="">
                  <div className=" date">
                    {moment(comment.createdAt).tz('Atlantic/Bermuda').format('MMM DD, YYYY hh:mm A')}
                  </div>
                  <div className=" name">
                    {`${comment.user.name} ${comment.user.last_name}`}
                  </div>
                </div>
                <p>{comment.message}</p>
              </div>
            </>
          )
      }

    </div>
  );
}

CommentLog.propTypes = {
  comment: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

// endregion

// region TASKLOSG
const TaskLogs = ({
  idTask, showLoader, statusList, task,
}) => {
  const limit = 50;
  const [page, setPage] = React.useState(1);
  const [logsList, setLogsList] = React.useState({ count: 0, rows: [] });
  const totalPages = Math.ceil(logsList.count / limit);
  const [previousHeight, setPrev] = React.useState(0);

  const token = sessionStorage.getItem('token');
  const decoded = jwtDecode(token);

  const getLosgs = React.useCallback(async () => {
    showLoader(true);
    const url = `tasks/${idTask}/logs?${makeUrlGET({ page, limit })}`;
    const request = await CreateAuthRequest('GET', null, true);
    fetchData(url, request)
      .then(response => {
        const newrows = [...response.rows, ...logsList.rows];
        setLogsList({
          count: response.count,
          rows: newrows.sort((a, z) => a.id_log - z.id_log),
        });
      })
      .finally(() => {
        if (page > 1) {
          const node = document.getElementsByClassName('logs-list')[0];
          node.scrollTop = node.scrollHeight - previousHeight;
          setPrev(node.scrollHeight);
        }
        showLoader(false);
      });
  }, [idTask, page]);

  React.useEffect(() => {
    getLosgs();
  }, [page, idTask, statusList]);

  React.useEffect(() => {
    setTimeout(() => {
      const node = document.getElementsByClassName('logs-list')[0];
      if (node) {
        node.scrollTop = node.scrollHeight;
        setPrev(node.scrollHeight);
      }
    }, 1000);
  }, []);

  const handleSubmit = async (message) => {
    showLoader(true);
    const url = `tasks/${idTask}/logs/message`;
    const request = await CreateAuthRequest('POST', { message }, true);
    fetchData(url, request)
      .then(response => {
        setLogsList({ count: logsList.count + 1, rows: [...logsList.rows, response] });
        setTimeout(() => {
          const node = document.getElementsByClassName('logs-list')[0];
          if (node) {
            node.scrollTop = node.scrollHeight;
            setPrev(node.scrollHeight);
          }
        }, 500);
      })
      .finally(() => showLoader(false));
  };

  const handleLogType = (log) => {
    let lbllog = null;
    switch (log.type) {
      case 'Task/Comment':
        return <CommentLog key={log.id_log} comment={log} user={decoded} />;
      case 'Task/Created':
        lbllog = (
          <label className="lbl-log">
            <b>{`${log.user.name} ${log.user.last_name}`}</b>
            {' created task.'}
          </label>
        );
        break;
      case 'Task/StatusUpdated':
        const statusAfter = log.metadata.id_status ? statusList.find(status => status.id_status === log.metadata.id_status.after)?.name : 1;
        const statusBefore = log.metadata.id_status ? statusList.find(status => status.id_status === log.metadata.id_status.before)?.name : 1;
        lbllog = (
          statusAfter === 1
            ? (
              <label className="lbl-log">
                <b>{`${log.user.name} ${log.user.last_name}`}</b>
                {' changed status'}
              </label>
            )
            : (
              <label className="lbl-log">
                {'Status change from '}
                <b>{statusBefore}</b>
                {' to '}
                <b>{statusAfter}</b>
                {' by '}
                <b>{`${log.user.name} ${log.user.last_name}`}</b>
              </label>
            )
        );
        break;
      case 'Task/AddedUser':
        lbllog = (
          <label className="lbl-log">
            <b>{`${log.user.name} ${log.user.last_name}`}</b>
            {' added user.'}
          </label>
        );
        break;
      case 'Task/Updated':
        lbllog = (
          <label className="lbl-log">
            <b>{`${log.user.name} ${log.user.last_name}`}</b>
            {' updated task.'}
          </label>
        );
        break;
      case 'Task/LocationUpdated':
        lbllog = (
          <label className="lbl-log">
            <b>{`${log.user.name} ${log.user.last_name}`}</b>
            {' updated task location.'}
          </label>
        );
        break;
      case 'Task/DeletedUserTask':
        lbllog = (
          <label className="lbl-log">
            <b>{`${log.user.name} ${log.user.last_name}`}</b>
            {' deleted a user.'}
          </label>
        );
        break;
      case 'Task/CreatedTaskFile':
        lbllog = (
          <label className="lbl-log">
            <b>{`${log.user.name} ${log.user.last_name}`}</b>
            {' added a file.'}
          </label>
        );
        break;
      case 'Task/DeletedTaskFile':
        lbllog = (
          <label className="lbl-log">
            <b>{`${log.user.name} ${log.user.last_name}`}</b>
            {' deleted a file.'}
          </label>
        );
        break;
      case 'Task/CreatedTaskList':
        lbllog = (
          <label className="lbl-log">
            <b>{`${log.user.name} ${log.user.last_name}`}</b>
            {' created a to-do list.'}
          </label>
        );
        break;
      case 'Task/CreatedTaskListItem':
        lbllog = (
          <label className="lbl-log">
            <b>{`${log.user.name} ${log.user.last_name}`}</b>
            {' added an item in to-do list.'}
          </label>
        );
        break;
      case 'Task/UpdatedTaskListItem':
        lbllog = (
          <label className="lbl-log">
            <b>{`${log.user.name} ${log.user.last_name}`}</b>
            {' updated an item in to-do list.'}
          </label>
        );
        break;
      case 'Task/DeletedItemTaskListUser':
        lbllog = (
          <label className="lbl-log">
            <b>{`${log.user.name} ${log.user.last_name}`}</b>
            {' deleted an item in todo list.'}
          </label>
        );
        break;
      case 'Task/CreatedItemTaskListUser':
        lbllog = (
          <label className="lbl-log">
            <b>{`${log.user.name} ${log.user.last_name}`}</b>
            {' added user to item in to-do list.'}
          </label>
        );
        break;
      default:
        lbllog = (
          <label className="lbl-log">
            <b>{`${log.user.name} ${log.user.last_name}`}</b>
            {` ${log.type}.`}
          </label>
        );
        break;
    }
    /* TODO:
     // 'Task/Created'
      `${user} created task.`
      // 'Task/StatusUpdated'
      `Status change from ${statusBefore} to ${statusAfter} by ${user}.`
      // 'Task/AddedUser'
      `${user} added user.`
      //'Task/Updated'
      `${user} updated task.`
      // 'Task/LocationUpdated'
      `${user} updated task location.`
      // 'Task/DeletedUserTask':
      `${user} deleted a user.`
      // 'Task/CreatedTaskFile':
      `${user} added file.`
      // 'Task/CreatedTaskList':
      `${user} created a to-do list.`
      // 'Task/CreatedTaskListItem':
      `${user} added an item in to-do list.`
      // 'Task/UpdatedTaskListItem':
      `${user} updated an item in to-do list.`
      // 'Task/DeletedItemTaskListUser':
      `${user} deleted an item in to-do list.`
      // 'Task/CreatedItemTaskListUser':
      `${user} added user to item in to-do lists.`
    */

    return (
      <div className="log-registry" key={log.id_log}>
        <div className="date">
          {moment(log.createdAt).tz('Atlantic/Bermuda').format('MMM DD, YYYY hh:mm a')}
        </div>
        {lbllog}
      </div>
    );
  };

  const handleOnScroll = ({ currentTarget }) => {
    if (currentTarget.scrollTop === 0) {
      const newPage = page + 1;
      if (newPage <= totalPages) {
        setPage(page + 1);
      }
    }
  };

  return (
    <div className="col chat">
      <div
        className="logs-list"
        onScroll={handleOnScroll}
      >
        {logsList.rows.map(log => (
          handleLogType(log)
        ))}
      </div>
      <div className="add-message">
        <AddComment onSubmit={handleSubmit} idTask={idTask} />
      </div>
    </div>
  );
};

TaskLogs.propTypes = {
  idTask: PropTypes.string.isRequired,
  showLoader: PropTypes.func,
  statusList: PropTypes.array,
  task: PropTypes.object,
};

TaskLogs.defaultProps = {
  showLoader: f => f,
  statusList: [],
  task: null,
};

// endregion
export default TaskLogs;
