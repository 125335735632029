/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import './paginacion.scss';

const GetPagination = ({
  total, page, limit, pageClick, showLimit,
}) => {
  const pages = Math.ceil(total / limit);
  const clickOnPage = (num) => {
    if (num <= pages && num > 0) {
      pageClick(num);
    }
  };

  const getPages = () => {
    const items = [];
    for (let i = (page - 3); i <= (page + 3); i += 1) {
      if (i > 0 && i <= pages) {
        items.push(i);
      }
    }
    return items.map((elm) => {
      if (elm === page) {
        return (
          <li className="page-item active" key={elm}>
            <button type="button" className="page-link">
              {elm} <span className="sr-only">(current)</span>
            </button>
          </li>
        );
      }
      return (
        <li className="page-item" key={elm} onClick={() => clickOnPage(elm)}>
          <button type="button" className="page-link">
            {elm}
          </button>
        </li>
      );
    });
  };

  return (
    <nav className="pagination nav justify-content-end">
      {
        showLimit
        && (
          <li className="mr-auto col-md-3">
            {`Showing ${limit} results`}
          </li>
        )
      }
      <li className="page-item" disabled={page === 1} onClick={() => clickOnPage(page - 1)}>
        <button type="button" className="page-link" aria-label="Previous">
          <i className="fas fa-angle-left" />
          {' Previous'}
        </button>
      </li>

      {getPages()}

      <li className="page-item" disabled={page === pages} onClick={() => clickOnPage(page + 1)}>
        <button type="button" className="page-link">
          {'Next '}
          <i className="fas fa-angle-right" />
        </button>
      </li>
    </nav>
  );
};

GetPagination.propTypes = {
  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  showLimit: PropTypes.bool,
  pageClick: PropTypes.func,
};

GetPagination.defaultProps = {
  total: 0,
  page: 1,
  limit: 10,
  showLimit: false,
  pageClick: (f) => f,
};

export default GetPagination;
