/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import ChatConversation from './chatConversation';
import ChatList from './chatList';
import ChatContextProvider from './chatContext/chatContextProvider';
import './chat.scss';

const Chat = ({ showLoader, NotificationManager }) => {
  const [update, setUpdate] = React.useState(false);
  return (
    <div className="chat shadow">
      <div className="row">
        <ChatContextProvider>
          <ChatList showLoader={showLoader} update={update} />
          <ChatConversation setUpdate={setUpdate} showLoader={showLoader} NotificationManager={NotificationManager} />
        </ChatContextProvider>
      </div>
    </div>
  );

}

Chat.propTypes = {
  showLoader: PropTypes.func,
  NotificationManager: PropTypes.object,
};

Chat.defaultProps = {
  showLoader: (f) => f,
  NotificationManager: null,
};
export default Chat;
