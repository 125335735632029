/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import './files.scss';

const acceptedFiles = ['pdf', 'jpg', 'doc', 'docx', 'xls', 'xlsx', 'png', 'jpeg', 'svg', 'gif'];

const InputFile = ({ label, handleFileUpload, NotificationManager }) => {
  const [file, setFile] = React.useState(null);
  const [isOver, setIsOver] = React.useState(false);

  const handleDrop = (e) => {
    e.preventDefault();
    const newFile = e.dataTransfer.items[0].getAsFile();
    if (newFile) {
      if (acceptedFiles.includes(newFile.name.split('.').pop().toLowerCase())) {
        setFile(newFile);
      } else {
        NotificationManager.warning('This type of file is not allowed.', 'Warning!', 5000);
      }
    }
    setIsOver(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsOver(false);
  };

  const handleInputChange = (e) => {
    const { files } = e.target;
    const newFile = files[0];
    if (newFile) {
      if (acceptedFiles.includes(newFile.name.split('.').pop().toLowerCase())) {
        setFile(newFile);
      } else {
        NotificationManager.warning('This type of file is not allowed.', 'Warning!', 5000);
      }
    }
  };

  const deleteFile = () => {
    // TODO: limpiar también el input file
    setFile(null);
  };

  const uploadFile = async () => {
    handleFileUpload(file);
    setFile(null);
  };

  return (
    <div className="files-section">
      {label && <label>{label}</label>}
      <div className="row">
        <div
          className={`col-12 col-md-6 drop-file ${isOver ? 'over' : ''}`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <label className="w-100">
            <div className="placeholder">
              <i className="fas fa-plus" />
              {' click or drop file to upload'}
            </div>
            <input
              type="file"
              className="hide"
              accept="pdf, jpg, doc, docx, xls,xlsx, png, jpeg, svg, gif"
              onChange={handleInputChange}
            />
          </label>
        </div>
        {
          file && (
            <div className="col-12 col-md-6 div-filename">
              <i role="button" className="close fas fa-times" onClick={deleteFile} />
              <div>{file.name}</div>
              <button type="button" className="btn btn-success" onClick={uploadFile}>
                <i className="fas fa-upload" />
                Upload file
              </button>
            </div>
          )
        }
      </div>
    </div>

  );
};

InputFile.propTypes = {
  label: PropTypes.string,
  handleFileUpload: PropTypes.func,
  NotificationManager: PropTypes.func,
};

InputFile.defaultProps = {
  label: null,
  handleFileUpload: f => f,
  NotificationManager: f => f,
};

export default InputFile;
