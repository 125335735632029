/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import DateTime from 'react-datetime';
import FileSaver from 'file-saver';
import TypeaheadWithOptions from '../../components/Typeahead/typeahead';
import { baseUrl, CreateAuthRequest } from '../../API/constants';

const today = moment();
const firstDayOfCurrentMonth = moment().startOf('month');

const Reports = ({ showLoader, NotificationManager }) => {
  const [filters, setFilters] = React.useState({
    type: '',
    id_user: null,
    id_section: null,
    from: firstDayOfCurrentMonth,
    to: today,
  });

  const [validated, setValidated] = React.useState(false);

  const handleFiltersChange = ({ target }) => {
    const { name, value } = target;
    setFilters({ ...filters, [name]: value });
  };

  const handleDatesChange = ({ target }) => {
    const { name, value } = target;
    const momentValue = moment(value);
    if (name === 'from') {
      if (momentValue.isAfter(moment(filters.to))) {
        setFilters({ ...filters, from: momentValue, to: momentValue });
      } else {
        setFilters({ ...filters, [name]: momentValue });
      }
    } else if (momentValue.isBefore(moment(filters.from))) {
      setFilters({ ...filters, from: momentValue, to: momentValue });
    } else {
      setFilters({ ...filters, [name]: momentValue });
    }
  };

  const handleSubmitGenerate = async (e) => {
    e.preventDefault();
    setValidated(true);
    if (e.target.checkValidity() && filters.from !== null && filters.to !== null) {
      console.log(filters);
      let type = 'general';
      if (filters.type === 'sections') {
        if (filters.id_section === null) {
          NotificationManager.warning('Choose section.', 'Warning!', 5000);
          return;
        }
      } else if (filters.type === 'users') {
        if (filters.id_user === null) {
          NotificationManager.warning('Choose section.', 'Warning!', 5000);
          return;
        }
      }
      let url = `reports/general?from=${moment(filters.from).toISOString()}&to=${moment(filters.to).toISOString()}`;
      if (filters.type !== '') {
        url = filters.type === 'sections'
          ? `reports/sections?from=${moment(filters.from).toISOString()}&to=${moment(filters.to).toISOString()}&id_section${filters.id_section}`
          : `reports/workers?from=${moment(filters.from).toISOString()}&to=${moment(filters.to).toISOString()}&id_user${filters.id_user}`;

        type = filters.type === 'sections'
          ? 'section'
          : 'user';
      }
      showLoader(true);
      const request = await CreateAuthRequest('GET', null, true);
      fetch(`${baseUrl}${url}`, request)
        .then(async (response) => {
          if (response.status === 200) {
            const file = await response.blob();
            FileSaver.saveAs(file, `${type}-report-${moment(filters.from).format('MMM/DD/YYYY')}-${moment(filters.to).format('MMM/DD/YYYY')}.xlsx`);
          } else {
            const error = await response.json();
            NotificationManager.error(error.message, 'Error!', 5000);
          }
        })
        .catch((error) => {
          NotificationManager.error(error, 'Error!', 5000);
        })
        .finally(() => {
          showLoader(false);
        });
    }
  };

  return (
    <div className="reports">
      <div className="card">
        <div className="card-body">
          <h3>Reports</h3>
          <form className={`form needs-validation ${validated ? ' was-validated' : ''}`} onSubmit={handleSubmitGenerate} noValidate>
            <div className="row">
              <div className="col-md-4">
                <label>Report type</label>
                <select className="form-control input-shadow" name="type" value={filters.type} onChange={handleFiltersChange}>

                  <option value="">General</option>
                  <option value="section">By Section</option>
                  <option value="user">By User</option>
                </select>
              </div>
              <div className="col-md-4 col-12">
                <div className="from-group">
                  <label>Dates</label>
                  <div className="input-group input-shadow">
                    <div className="form-control" style={{ padding: '0', border: '0', backgroundColor: '#fff' }}>
                      <DateTime
                        closeOnSelect
                        dateFormat="DD/MMM/YYYY"
                        timeFormat={false}
                        onChange={(dateMoment) => handleDatesChange({ target: { name: 'from', value: moment(dateMoment) } })}
                        value={filters.from && moment(filters.from).format('DD/MMM/YYYY')}
                        inputProps={{
                          placeholder: 'Start date',
                          required: true,
                          readOnly: true,
                          style: { backgroundColor: '#fff' },
                        }}
                      />
                    </div>
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">to</span>
                    </div>
                    <div className="form-control" style={{ padding: '0', border: '0', backgroundColor: '#fff' }}>
                      <DateTime
                        closeOnSelect
                        dateFormat="DD/MMM/YYYY"
                        timeFormat={false}
                        onChange={(dateMoment) => handleDatesChange({ target: { name: 'to', value: moment(dateMoment) } })}
                        value={filters.to && moment(filters.to).format('DD/MMM/YYYY')}
                        inputProps={{
                          placeholder: 'End date',
                          required: true,
                          readOnly: true,
                          style: { backgroundColor: '#fff' },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {
                filters.type !== ''
                && (
                  filters.type === 'section'
                    ? (
                      <div className="col-md-4 col-12">
                        <label>Section</label>
                        <TypeaheadWithOptions
                          // labelKey={(option) => (`${option.id} ${option.name}`)}
                          labelKey="name"
                          idLabel="id_section"
                          url="sections"
                          multiple={false}
                          minLength={3}
                          onSelect={(option) => handleFiltersChange({ target: { name: 'id_section', value: option?.id_section } })}
                          placeholder="Type section to search..."
                          renderOptions={(option) => (
                            <span>{`${option.id_section} ${option.name}`}</span>
                          )}
                          inputProps={{ required: true, id: 'section' }}
                        />
                      </div>
                    )
                    : (
                      <div className="col-md-4 col-12">
                        <label>User</label>
                        <TypeaheadWithOptions
                          labelKey={(option) => (`${option.name} ${option.last_name}`)}
                          idLabel="id_user"
                          url="users"
                          multiple={false}
                          minLength={3}
                          onSelect={(option) => handleFiltersChange({ target: { name: 'id_user', value: option?.id_section } })}
                          placeholder="Type user to search..."
                          renderOptions={(option) => (
                            <span>{`${option.name} ${option.last_name}`}</span>
                          )}
                          inputProps={{ required: true, id: 'user' }}
                        />
                      </div>
                    )
                )
              }
            </div>
            <div className="mg-top tac">
              <button type="submit" className="btn btn-primary btn-submit btn-radius">Generate</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

Reports.propTypes = {
  NotificationManager: PropTypes.object,
  showLoader: PropTypes.func,
};

Reports.defaultProps = {
  NotificationManager: null,
  showLoader: (f) => f,
};

export default Reports;
