/* eslint-disable prefer-const */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable arrow-parens */
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import icon from '../assets/images/icon_ball.png';

export const baseUrl = `${process.env.REACT_APP_API_URL}`;

export const Methods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const priority = [
  {
    id: 1, name: 'Low', color: '#2D9CDB', class: 'info',
  },
  {
    id: 2, name: 'Medium', color: '#ED7C18', class: 'warning',
  },
  {
    id: 3, name: 'High', color: '#C81A1A', class: 'danger',
  },
];

export const difficulty = [
  {
    id: 1, name: 'Low', color: '#2D9CDB', class: 'info',
  },
  {
    id: 2, name: 'Medium', color: '#ED7C18', class: 'warning',
  },
  {
    id: 3, name: 'High', color: '#C81A1A', class: 'danger',
  },
];

export function getToken(decode = false) {
  const token = sessionStorage.getItem('token');
  if (decode) {
    const decoded = jwtDecode(token);
    return decoded;
  }
  return token;
}

export const makeUrlGET = (objs) => {
  const params = Object.keys(objs)
    .filter(key => objs[key])
    .map(key => {
      const val = objs[key];
      return (`${key}=${val}`);
    });
  return params.join('&');
};

const refreshToken = () => {
  const url = `${baseUrl}auth/token`;
  const headers = new Headers();
  headers.append('authorization', `Bearer ${sessionStorage.token}`);
  const request = { method: Methods.POST, headers };
  return fetch(url, request)
    .then(response => response.json())
    .then(response => response.token);
};

export const UpdateToken = async () => {
  if (sessionStorage.token && sessionStorage.token !== 'undefined') {
    const currentTime = moment().unix();
    const decoded = jwtDecode(sessionStorage.token);
    if (decoded.exp < currentTime) {
      const currentToken = await refreshToken();
      sessionStorage.setItem('token', currentToken);
      return sessionStorage.getItem('token');
    }
    return sessionStorage.getItem('token');
  }
  sessionStorage.removeItem('token');
  return null;
};

export const CreateAuthRequest = async (method, body, isJson = true) => {
  const token = await UpdateToken();
  const request = {
    method,
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
  };
  if (isJson) {
    if (body) {
      request.body = JSON.stringify(body);
    }
  } else {
    request.headers.delete('Content-Type');
    request.body = body;
  }

  return request;
};

export const fetchData = async (url, request) => new Promise((resolve, reject) => fetch(`${baseUrl}${url}`, request)
  .then(response => {
    if (response.status === 200) {
      resolve(response.json());
    } else {
      reject(response.json());
    }
  })
  .catch(error => {
    reject(error);
  }));

// region Notifications
export let permissionNotification = Notification.permission;

export const AskNotificationPermission = () => {
  Notification.requestPermission((permission) => {
    if (permission === 'granted') {
      const n = new Notification('Power of one', { body: 'Now you wil recieve notifications.' });
      setTimeout(n.close.bind(n), 5000);
    }
  });
};

export const showNotification = (title, body, urlClick) => {
  const options = {
    body,
    icon,
  };
  const n = new Notification(title, options);
  n.onclick = () => { window.location.href = urlClick; };
  // setTimeout(n.close.bind(n), 5000);
};

showNotification.defaultProps = {
  urlClick: '/pannel/notifications',
};

// endregion
