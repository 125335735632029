/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CreateAuthRequest, fetchData } from '../../API/constants';
import './users.scss';
import Header from '../../components/header/header';

const ShowPasswordMessage = ({ match }) => {
  const message = match ? 'Passwords match.' : 'Passwords unmatch.';
  let label = '';
  if (match !== undefined) {
    label = (
      <label className={match ? 'match' : 'unmatch'}>
        <i className={match ? 'fas fa-check' : 'fas fa-times'} />
        {' '}
        {message}
      </label>
    );
  }
  return label;
};

const ChangePassword = ({ NotificationManager, showLoader }) => {
  const history = useHistory();
  const location = useLocation();
  const [infoUser, setInfoUser] = React.useState({});
  const [form, setForm] = React.useState({ password: '', confirmPassword: '', match: undefined });
  const [validated, setValidated] = React.useState(false);
  const [showPswd, setShowPswd] = React.useState(false);
  const [showconfirmPswd, setShowConformPswd] = React.useState(false);
  const { idUser } = useParams();
  const isProfile = location.pathname.includes('profile');

  const getUserInfo = React.useCallback(async () => {
    if (idUser) {
      const request = await CreateAuthRequest('GET', null, true);
      fetchData(`users/${idUser}`, request)
        .then((response) => {
          setInfoUser({
            name: response.name,
            last_name: response.last_name,
            email: response.email,
            phone_number: response.phone_number,
            type: response.type,
          });
        });
    }
  }, [idUser]);

  React.useEffect(() => { getUserInfo(); }, [getUserInfo]);

  const handleFormChange = ({ target }) => {
    const { name, value } = target;

    if (name === 'confirmPassword') {
      if (form.password.length >= 3) {
        setForm({ ...form, [name]: value, match: form.password === value });
      } else {
        setForm({ ...form, [name]: value, match: undefined });
      }
    } else if (form.confirmPassword.length >= 3) {
      setForm({ ...form, [name]: value, match: form.confirmPassword === value });
    } else {
      setForm({ ...form, [name]: value, match: undefined });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);
    if (e.target.checkValidity() && form.match) {
      showLoader(true);
      const url = isProfile ? 'me' : `users/${idUser}`;
      const request = await CreateAuthRequest('PUT', { password: form.password }, true);
      fetchData(url, request)
        .then(() => {
          NotificationManager.success('Password updated.', 'Success!', 5000);
          if (isProfile) {
            history.replace(`/pannel/users/profile/${idUser}`);
          } else {
            history.replace(`/pannel/users/details/${idUser}`);
          }
        })
        .catch((response) => {
          try {
            response.then((error) => {
              NotificationManager.error(error.message, 'Error!', 5000);
            });
          } catch (error) {
            NotificationManager.error(error, 'Error!', 5000);
          }
        })
        .finally(() => {
          showLoader(false);
        });
    }
  };

  function TogglePassword() {
    setShowPswd(!showPswd);
  }

  function ToggleNewPassword() {
    setShowConformPswd(!showconfirmPswd);
  }

  return (
    <div className="users-new">
      <Header title="Change password" />
      <div className="card">
        <div className="card-body">
          <h4><b>User:</b></h4>
          <h3>{`${infoUser.name} ${infoUser.last_name} - ${infoUser.email} `}</h3>
          <hr />
          <form className={`form needs-validation ${validated ? ' was-validated' : ''}`} onSubmit={handleSubmit} noValidate>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group tal">
                  <label className="label-title">Password</label>
                  <div className="input-group w-100">
                    <input
                      id="password"
                      name="password"
                      type={showPswd ? 'text' : 'password'}
                      className="form-control"
                      value={form.password}
                      onChange={handleFormChange}
                      minLength="4"
                      placeholder="Type your new password."
                      required
                    />
                    <div className="input-group-append">
                      <button id="btn-sp" className="btn  btn-show-password" style={{ border: '1px solid #cccc' }} onClick={TogglePassword} type="button">
                        {
                          showPswd
                            ? <span className="far fa-eye-slash" />
                            : <span className="far fa-eye" />
                        }
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group tal">
                  <label className="label-title">Confirm Password</label>
                  <div className="input-group w-100">
                    <input
                      type={showconfirmPswd ? 'text' : 'password'}
                      id="confirmPassword"
                      name="confirmPassword"
                      className="form-control"
                      value={form.confirmPassword}
                      onChange={handleFormChange}
                      minLength="4"
                      placeholder="Confirm your password."
                      required
                    />
                    <div className="input-group-append">
                      <button id="btn-sp" className="btn  btn-show-password" style={{ border: '1px solid #cccc' }} onClick={ToggleNewPassword} type="button">
                        {
                          showconfirmPswd
                            ? <span className="far fa-eye-slash" />
                            : <span className="far fa-eye" />
                        }
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <ShowPasswordMessage match={form.match} />
              </div>
            </div>
            <div className="div-submit tac">
              <button className="btn btn-submit btn-primary btn-radius" type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

ChangePassword.propTypes = {
  NotificationManager: PropTypes.object,
  showLoader: PropTypes.func,
};

ChangePassword.defaultProps = {
  NotificationManager: null,
  showLoader: (f) => f,
};

export default ChangePassword;
