/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { CreateAuthRequest, fetchData } from '../../API/constants';
import Typeahead from '../../components/CustomizedTypeahead/typeahead';
import TypeaheadWithOptions from '../../components/Typeahead/typeahead';
import Pagination from '../../components/Pagination/pagination';
import ModalConfirm from '../../components/ModalConfirm/modalConfirm';

const JobsBySection = ({ showLoader, NotificationManager, idUser, isProfile }) => {
  const [jobList, setJobList] = React.useState({ count: 0, rows: [] });
  const [form, setForm] = React.useState({});
  const [validated, setValidated] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [defaultSelected, setDefaultSelected] = React.useState(null);

  const [modalConfirm, setModalConfirm] = React.useState({ show: false, message: '' });

  const getUsersJobs = React.useCallback(async () => {
    if (idUser) {
      showLoader(true);
      const request = await CreateAuthRequest('GET', null, true);
      fetchData(`users/${idUser}/jobs?page=${page}`, request)
        .then((response) => {
          setJobList(response);
        })
        .finally(() => {
          showLoader(false);
        });
    }
  }, [idUser, page]);

  React.useEffect(() => { getUsersJobs(); }, [getUsersJobs]);

  const handleFormChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);
    if (e.target.checkValidity() && form.id_job > 0) {
      showLoader(false);
      const request = await CreateAuthRequest('POST', { users: [idUser] }, true);
      fetchData(`sections/jobs/${form.id_job}/users`, request)
        .then((response) => {
          setForm({});
          const obj = {
            count: jobList.count + 1,
            rows: [response, ...jobList.rows],
          };
          setJobList(obj);
          NotificationManager.success('Job has been assigned.', 'Success!', 5000);
          setDefaultSelected({ title: '' });
          const closebtn = document.getElementsByClassName('close rbt-close');
          if (closebtn.length > 0) {
            if (closebtn.length > 1) {
              closebtn[1].click();
            }
            closebtn[0].click();
          }
          setValidated(false);
        })
        .catch((response) => {
          try {
            response.then((error) => {
              NotificationManager.error(error.message, 'Error!', 5000);
            });
          } catch (error) {
            NotificationManager.error(error, 'Error!', 5000);
          }
        })
        .finally(() => {
          showLoader(false);
          setDefaultSelected(null);
        });
    } else {
      NotificationManager.warning('Complete fields.', 'Warning!', 5000);
    }
  };

  const handlePageClick = (newPage) => {
    setPage(newPage);
  };

  const hideModal = () => {
    setModalConfirm({ show: false, idJob: 0, message: '' });
  };

  const showModal = (job) => {
    setModalConfirm({ show: true, idJob: job.id_job, message: job.title });
  };

  const UnassignJob = async () => {
    const request = await CreateAuthRequest('DELETE', null, true);
    fetchData(`sections/jobs/${modalConfirm.idJob}/users/${idUser}`, request)
      .then(() => {
        const obj = {
          count: jobList.count - 1,
          rows: jobList.rows.filter((job) => job.id_job !== modalConfirm.idJob),
        };
        setJobList(obj);
        NotificationManager.success('Job unassigned successfully.', 'Success!', 5000);
      })
      .catch((err) => {
        try {
          err.then((er) => {
            NotificationManager.error(er.message, 'Error!', 5000);
          });
        } catch (error) {
          NotificationManager.error(error, 'Error!', 5000);
        }
      })
      .finally(() => {
        showLoader(false);
        hideModal();
      });
  };

  return (
    <div>
      <hr />
      {
        !isProfile
        && (
          <>
            <label style={{ fontSize: '20px', fontWeight: '700' }}>Jobs</label>
            <h5>Assing new job</h5>
            <form className={`form needs-validation ${validated ? ' was-validated' : ''}`} onSubmit={handleSubmit} noValidate>
              <div className="row">
                <div className="col-4">
                  <div className="form-group">
                    <label>Section</label>
                    <Typeahead
                      labelKey="name"
                      url={`${process.env.REACT_APP_API_URL}sections`}
                      multiple={false}
                      minLength={3}
                      onChange={(option) => handleFormChange({ target: { name: 'id_section', value: option[0]?.id_section } })}
                      placeholder="Filter by section..."
                      itemChildren={(option) => (
                        <span>{`${option.id_section} ${option.name}`}</span>
                      )}
                      inputProps={{ required: 'required' }}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Job</label>
                    <TypeaheadWithOptions
                      labelKey="title"
                      idLabel="id_job"
                      url="sections/jobs"
                      filterBy={`id_section=${form.id_section}`}
                      multiple={false}
                      minLength={3}
                      onSelect={(option) => handleFormChange({ target: { name: 'id_job', value: option?.id_job } })}
                      placeholder={!form.id_section ? 'You need to choose section first' : 'Type job title to search...'}
                      renderOptions={
                        (option) => (
                          <div>
                            <div>{option.title}</div>
                          </div>
                        )
                      }
                      defaultSelected={defaultSelected}
                      inputProps={{ disabled: !form.id_section }}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4 tac">
                  <button type="submit" className=" mg-top btn btn-primary btn-radius">Assing</button>
                </div>
              </div>
            </form>
          </>
        )
      }

      <h5>Jobs List</h5>
      <div className="table-section">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Section</th>
                <th>Job Title</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>
              {
                jobList.count > 0
                  ? jobList.rows.map((job) => (
                    <tr>
                      <td>{job.section.name}</td>
                      <td>{job.title}</td>
                      <td>
                        <button type="button" className="btn btn-danger btn-radius" onClick={() => showModal(job)}> Unassign</button>
                      </td>
                    </tr>
                  ))
                  : <tr><td colSpan="3">0 jobs found</td></tr>
              }
            </tbody>
          </table>

          <Pagination
            total={jobList.count}
            page={page}
            limit={10}
            pageClick={handlePageClick}
            showLimit={false}
          />

        </div>
      </div>

      <ModalConfirm
        show={modalConfirm.show}
        message={modalConfirm.message}
        type="unassign"
        onHide={hideModal}
        onConfirm={UnassignJob}
      />
    </div>
  );
};

JobsBySection.propTypes = {
  NotificationManager: PropTypes.object,
  showLoader: PropTypes.func,
  idUser: PropTypes.number.isRequired,
  isProfile: PropTypes.bool.isRequired,
};

JobsBySection.defaultProps = {
  NotificationManager: null,
  showLoader: (f) => f,
};

export default JobsBySection;
