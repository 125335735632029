/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import DraggableCardContainer from './cardContainer';
import './draggable.scss';

// region P a n e l   d r a g g a b l e
// contenedor de las columnas o listas de tarjetas
const DraggablePanel = (props) => {
  const {
    statusLabel, idKey, view, clickOnCard, initialData, pannels, cards, component,
  } = props;
  const [draggable, setDraggable] = React.useState(null);
  const [mappedData, setMapData] = React.useState([]);

  React.useEffect(() => {
    function mapData() {
      if (initialData.length > 0) {
        return initialData;
      }

      if (pannels.length === 0) {
        return initialData;
      }

      if (cards.length === 0) {
        return pannels;
      }

      const pannelsIds = pannels.map(elm => elm.id);
      const pannelsArray = pannels.map(elm => ({ ...elm, cards: [] }));
      for (let i = 0; i < cards.length; i += 1) {
        const card = cards[i];
        const idx = pannelsIds.indexOf(card[statusLabel]);
        pannelsArray[idx] = {
          ...pannelsArray[idx],
          counter: pannelsArray[idx].counter + 1,
          cards: [...pannelsArray[idx].cards, card],
        };
      }
      return pannelsArray;
    }
    setMapData(mapData());

    return () => {
      // setMapData([]);
    };
  }, [initialData, pannels, cards, statusLabel]);

  const mapCardsOnDrop = async (card, newStatus) => {
    if (card !== null) {
      if (card[statusLabel] !== newStatus) {
        const change = await props.dropDrag(card, newStatus);
        if (change) {
          setMapData(() => {
            // const { id_estatus_cotizacion, ...newcard } = card;
            const newcards = mappedData.map(container => {
              if (parseInt(container.id, 10) === parseInt(card[statusLabel], 10)) {
                const obj = {
                  ...container,
                  counter: container.counter - 1,
                  cards: container.cards.filter(elm => elm[idKey] !== card[idKey]),
                };

                return obj;
              }

              if (container.id === newStatus) {
                const obj = {
                  ...container,
                  counter: container.counter + 1,
                  cards: [
                    { ...card, [statusLabel]: newStatus },
                    ...container.cards,
                  ],
                };

                return obj;
              }

              return container;
            });

            return newcards;
          });
        }
      }
    }
  };

  return (
    <div className={`card-box-container ${view}`}>
      {
        mappedData.map(container => (
          <DraggableCardContainer
            key={container.id}
            title={container.title}
            counter={container.counter}
            color={container.color}
            id={container.id}
            cards={container.cards || []}
            view={view}
            dropDrag={mapCardsOnDrop}
            draggable={draggable}
            setDraggable={setDraggable}
            clickOnCard={clickOnCard}
            idKey={idKey}
            statusLabel={statusLabel}
            component={component}
            isRestricted={container.isRestricted}
            hide={container.hide}
          />
        ))
      }
    </div>
  );
};

DraggablePanel.propTypes = {
  view: PropTypes.string,
  clickOnCard: PropTypes.func,
  initialData: PropTypes.array,
  dropDrag: PropTypes.func,
  pannels: PropTypes.array,
  cards: PropTypes.array,
  idKey: PropTypes.string,
  statusLabel: PropTypes.string,
  component: PropTypes.func,
};

DraggablePanel.defaultProps = {
  view: 'kanban',
  clickOnCard: f => f,
  initialData: [],
  dropDrag: () => true,
  pannels: [],
  cards: [{
    id: 'c201-0p',
    description: 'Impresión de tikect redondo transparente 5000 piezas.',
    client: "Fernis dal'Ignis",
    date: '30/03/2019',
    total: 0,
    release_date: '30/03/2019',
    hasFiles: false,
    status: '1',
  }],
  idKey: 'id_task',
  statusLabel: 'status',
  component: null,
};
// endregion

export default DraggablePanel;
